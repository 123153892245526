import React, {InputHTMLAttributes, TextareaHTMLAttributes} from 'react';

import {ErrorText, Field, Input, Label, TextArea} from './styles';

type HTMLTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
type HTMLInputProps = InputHTMLAttributes<HTMLInputElement>;

type CommonProps = {
    error?: string;
    label?: string;
    placeholder?: string;
    fieldClassName?: string;
};

type TextAreaProps = HTMLTextAreaProps &
    CommonProps & {
        multiline: true;
    };

export type TextInputProps = HTMLInputProps &
    CommonProps & {
        multiline?: false;
    };

type FieldType = HTMLTextAreaProps | HTMLInputProps;

const isMultiline = (
    props: FieldType,
    multiline?: boolean,
): props is HTMLTextAreaProps => {
    return Boolean(multiline);
};

export const TextInput = React.forwardRef<
    HTMLInputElement | HTMLTextAreaElement,
    TextInputProps | TextAreaProps
>(({multiline, label, fieldClassName, error, ...props}, ref) => {
    return (
        <Field className={fieldClassName}>
            <Label>{label}</Label>
            {isMultiline(props, multiline) ? (
                <TextArea
                    {...props}
                    ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
                />
            ) : (
                // @ts-ignore
                <Input
                    {...props}
                    ref={ref as React.ForwardedRef<HTMLInputElement>}
                />
            )}
            <ErrorText>{typeof error === 'string' && error}</ErrorText>
        </Field>
    );
});
