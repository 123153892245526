import styled, {css} from 'styled-components';

import Motion from '~components/atoms/Motion';
import RichText from '~components/atoms/RichText';
import {above, below} from '~config/breakpoints';
import Text, {fontFamilies, fonts} from '~components/atoms/Text';
import {StyledLink} from '~components/atoms/PageLink/styles';

export const ForBrandsContainer = styled.section`
    position: relative;
    background: var(--dark-grey);
    color: var(--white);
    --gap: 104px;
    padding: 0 var(--h-padding) var(--gap);
    display: flex;
    flex-wrap: wrap;
    gap: 104px 24px;

    ${above.wideDesktop} {
        gap: 104px 100px;
    }
`;

export const BackgroundTitle = styled.h3`
    // @FONTS: no style match
    ${fontFamilies.clashGrotesk}
    font-weight: 300;
    font-size: 88px;
    line-height: 100%;
    text-transform: uppercase;
    max-width: 868px;
    position: absolute;
    inset-inline: var(--h-padding);
    padding-top: 60px;
    padding-bottom: 400px;

    ${above.laptop} {
        padding-bottom: var(--gap);
        font-weight: 300;
        font-size: 160px;
        line-height: 100%;
    }
`;

export const TilesContainer = styled.div`
    display: grid;
    gap: 64px 72px;
    grid-template-columns: 1fr;
    z-index: 1;
    padding: 294px 0 0;

    ${above.laptop} {
        margin-left: auto;
        padding: 192px 0 0;
        gap: 104px 72px;
        grid-template-columns: repeat(2, 312px);
    }
`;

export const TileContainer = styled.div<{theme: 'dark' | 'light'}>`
    ${({theme}) => {
        switch (theme) {
            case 'dark':
                return css`
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    background: var(--dark-grey);
                    color: var(--white);
                `;
            case 'light':
                return css`
                    background: var(--white);
                    color: var(--dark-grey);
                `;
        }
    }}

    border-radius: 8px;
    padding: 32px 40px 40px;
    display: flex;
    flex-direction: column;

    ${StyledLink} {
        a {
            text-transform: uppercase;
            ${fonts.bodyRegular}
        }
        margin-top: 24px;
    }
`;

export const TileIcon = styled(Motion.img)`
    height: 32px;
    width: max-content;
    object-fit: scale-down;
`;

export const TileTitle = styled(Text.h6Regular).attrs({ignoreMobile: true})`
    margin: 12px 0 8px;
`;

export const TileDescription = styled(Text.bodySmallRegular)`
    opacity: 0.6;
    margin-bottom: auto;
    min-height: 130px;
`;

export const CtaContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    position: relative;

    ${StyledLink} {
        padding-block: 12px;
        padding-right: 36px;
        --black: #fff;
        --white: var(--dark-grey);
        z-index: 2;
        text-align: left;
    }

    ${above.laptop} {
        padding-top: 144px;
        padding-bottom: var(--gap);
        width: 186px;
        height: max-content;
    }

    ${below.tablet} {
        align-items: center;

        * {
            text-align: center;
        }
    }
`;

export const CtaTitle = styled(RichText).attrs({tag: 'p'})`
    z-index: 0;
`;

export const TestimonialsContainer = styled.div`
    position: relative;
    // hide subpixel gap
    top: -1px;
    background: var(--dark-grey);
    color: var(--white);
    padding: 0 var(--h-padding) 104px;
    display: grid;
    gap: 104px;
    width: 100%;
    grid-template-columns: max-content;
    justify-content: center;
    position: relative;
    grid-template-columns: minmax(10px, max-content);
`;

export const TestimonialContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 64px 128px;

    ${above.laptop} {
        &:nth-child(2n) {
            justify-content: flex-end;
            flex-direction: row-reverse;
        }
    }
`;

export const MainColumnContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 2;
    flex-basis: 200px;
`;

export const TestimonialText = styled(RichText).attrs({tag: 'p'})`
    max-width: 600px;
    margin-bottom: 24px;

    ${above.laptop} {
        margin-bottom: 51px;
    }
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 64px 104px;
`;

export const Author = styled(Text.bodyRegular)`
    white-space: pre-line;
    opacity: 0.5;

    ${above.laptop} {
        max-width: 200px;
    }
`;

export const Logo = styled(Motion.img)`
    max-width: 185px;
    max-height: 80px;
    margin-top: 24px;
`;

export const PrimaryAssetContainer = styled(Motion.div)`
    position: relative;
    width: 100%;
    max-height: max-content;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    max-width: 450px;
    margin-top: auto;

    ${above.laptop} {
        width: 392px;
    }

    video {
        border-radius: 8px;
    }
`;

export const SecondaryAssetContainer = styled(Motion.div)`
    position: relative;
    width: 100%;
    max-height: max-content;
    display: flex;
    border-radius: 8px;
    overflow: hidden;

    ${above.laptop} {
        width: 392px;
    }

    video {
        border-radius: 8px;
    }
`;
