import classNames from 'classnames';
import {HTMLAttributes, useMemo} from 'react';

import {useInViewAnimation} from '../Animations';
import {richTextClass} from '../Text';

type RichTextProps = {
    children: string | null | undefined;
    replacePWithBr?: boolean;
    animation?: boolean;
    tag?: keyof JSX.IntrinsicElements;
} & HTMLAttributes<HTMLDivElement>;

const RichText = ({
    children,
    tag = 'span',
    replacePWithBr = true,
    animation = true,
    className,
    ...rest
}: RichTextProps): JSX.Element => {
    const {ref, animationClassName} = useInViewAnimation();

    const html = useMemo(
        () =>
            children
                ? replacePWithBr
                    ? children
                          .replace(/<\/p><p>/g, '<br>')
                          .replace(/<p>/g, '')
                          .replace(/<\/p>/g, '')
                    : children
                : '',
        [children?.length],
    );

    const Component = tag as 'div';

    return (
        <Component
            dangerouslySetInnerHTML={{__html: html}}
            className={classNames(
                className,
                richTextClass,
                animation && animationClassName,
            )}
            {...rest}
            ref={ref}
        />
    );
};

export default RichText;
