import styled, {css} from 'styled-components';

import {fonts} from '../Text';

export const Answer = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    color: var(--dark-grey);
    opacity: 0.6;
    position: relative;

    &::before {
        // Emulate padding
        content: '';
        display: block;
        height: 8px;
        width: 100%;
    }

    ${fonts.bodyRegular}
`;

export const Question = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Row = styled.button<{active: boolean}>`
    background: #ffffff;
    padding: 16px 24px;
    border: 1px solid ${({active}) => (active ? '#000' : 'rgba(23, 24, 26, 0.1)')};
    border-radius: 8px;
    text-align: left;

    svg {
        transition: transform 0.3s ease-in-out;
    }

    ${({active}) =>
        active
            ? css`
                  svg {
                      transform: rotate(-180deg);
                  }
              `
            : null}
`;
