import {ContactForm} from '~components/molecules/ContactForm';
import {ContactFormSection as ContactFormSectionProps} from '~types/graphql/ContactFormSection';

import {ContactFormContainer, ContactFormSectionContainer, Title} from './styles';

const ContactFormSection = ({title}: ContactFormSectionProps) => {
    return (
        <ContactFormSectionContainer>
            <Title>{title}</Title>
            <ContactFormContainer>
                <ContactForm />
            </ContactFormContainer>
        </ContactFormSectionContainer>
    );
};

export default ContactFormSection;
