import {imageProps} from '@everdome_io/next-js-helper';
import {useMemo} from 'react';
import ReactPlayer from 'react-player';

import {ImageAndTextSectionChildrenImage} from '~types/graphql/ImageAndTextSection';

import {Image} from './styles';
type ImageOrVideoProps = {
    media: ImageAndTextSectionChildrenImage | null;
};

const ImageOrVideo = ({media}: ImageOrVideoProps) => {
    const videoData = useMemo(() => {
        return media?.data.attributes.mime.includes('video')
            ? imageProps(media)
            : null;
    }, []);

    return (
        <>
            {media?.data?.attributes.mime.includes('video') ? (
                <ReactPlayer
                    url={videoData?.src}
                    width="100%"
                    height="100%"
                    playing={true}
                    muted={true}
                    loop
                />
            ) : (
                <Image {...imageProps(media)} />
            )}
        </>
    );
};

export default ImageOrVideo;
