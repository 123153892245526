import {imageProps} from '~utils/imagePropsReplaceDomain';
import {ActivitiesSection as ActivitiesSectionProps} from '~types/graphql/ActivitiesSection';
import AnimatedImage from '~components/atoms/AnimatedImage';

import {
    Description,
    Title,
    ActivitiesContainer,
    ActivityCardContainer,
    Activity,
    CardInner,
    BackgroundContainer,
} from './styles';

const ActivitiesSection = ({activities}: ActivitiesSectionProps) => {
    return (
        <ActivitiesContainer>
            {activities.map(
                ({
                    activity,
                    title,
                    description,
                    cardTheme,
                    background,
                    backgroundImage,
                }) => (
                    <ActivityCardContainer key={`${activity}_${title}`}>
                        <BackgroundContainer background={background}>
                            {imageProps(backgroundImage)?.src && (
                                <AnimatedImage
                                    {...imageProps(backgroundImage)}
                                    width="100%"
                                    rounded={false}
                                />
                            )}
                        </BackgroundContainer>
                        <CardInner cardTheme={cardTheme}>
                            <Activity>{activity}</Activity>
                            <Title>{title}</Title>
                            <Description>{description}</Description>
                        </CardInner>
                    </ActivityCardContainer>
                ),
            )}
        </ActivitiesContainer>
    );
};

export default ActivitiesSection;
