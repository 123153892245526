import styled, {css, keyframes} from 'styled-components';

import DomeIconSvg from '~assets/icons/dome-medium.svg';
import BowSvg from '~assets/icons/bow.svg';
import Link from '~components/atoms/Link';
import Motion from '~components/atoms/Motion';
import RichText from '~components/atoms/RichText';
import Text, {fonts} from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';
import {StyledLink} from '~components/atoms/PageLink/styles';
export const ForInvestorsContainer = styled.section`
    position: relative;
    padding: 104px var(--h-padding);

    ${above.laptop} {
        padding: 183px var(--h-padding) 68px;
    }

    z-index: 0;

    background: linear-gradient(0deg, var(--dark-grey), var(--dark-grey));
    background-position-y: bottom;
    background-repeat: no-repeat;

    ${above.laptop} {
        background-size: 100% 300px;
    }

    ${above.desktop} {
        background-size: 100% 500px;
    }

    ${below.laptop} {
        background: none;
    }

    // Hide flickering
    margin-bottom: -1px;
`;

export const Bow = styled(BowSvg)`
    position: absolute;
    bottom: -60px;
    left: -40px;
    width: 110%;
    color: var(--white);

    ${below.laptop} {
        display: none;
    }
`;

export const TopRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px 80px;
    justify-content: space-between;
    z-index: 1;
    position: relative;
`;

export const Title = styled(RichText).attrs({tag: 'h2'})`
    max-width: 498px;
`;

export const Description = styled(RichText).attrs({tag: 'p'})`
    max-width: 496px;
    white-space: pre-line;
`;

export const Card = styled(Link)<{
    background: 'dark' | 'grey' | 'mars' | 'orange' | 'white';
}>`
    position: relative;
    padding: 48px 32px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 300ms ease-in;

    :hover {
        transition: all 600ms ease;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
        transform: translateY(-4px);
    }

    ${above.laptop} {
        padding: 64px 48px 48px;
    }

    ${({background}) => {
        switch (background) {
            case 'dark': {
                return css`
                    background: var(--dark-grey);
                    color: var(--white) !important;
                `;
            }
            case 'grey': {
                return css`
                    background: var(--grey);
                `;
            }
            case 'white': {
                return css`
                    background: var(--white);
                    border: 1px solid var(--dark-grey);
                `;
            }
            case 'orange': {
                return css`
                    background: var(--orange);
                    color: var(--white) !important;
                `;
            }
            case 'mars': {
                return css`
                    background: url('/assets/images/mars-bg.jpg') no-repeat;
                    background-size: cover;
                    color: var(--white) !important;
                `;
            }
        }
    }}

    &:not([href]) {
        pointer-events: none;
    }
`;

export const CardsContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px 24px;
    margin: 380px 0 80px;
    --offset: 84px;

    ${above.laptop} {
        gap: 80px 24px;
        margin: 128px 0 184px;
        grid-template-columns: repeat(3, 1fr);

        ${Card} {
            &:nth-of-type(3n + 2) {
                top: calc(var(--offset) * 1);
            }

            &:nth-of-type(3n) {
                top: calc(var(--offset) * 2);
            }
        }
    }

    ${above.wideDesktop} {
        gap: 80px 40px;
    }

    ${StyledLink} {
        text-transform: uppercase;
        ${fonts.bodyBold}
        margin-top: 24px;
        pointer-events: none;
    }
`;

export const DomeIcon = styled(DomeIconSvg)`
    position: absolute;
    max-height: 90%;
    height: 400px;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0.2;
`;

export const CardTitle = styled(RichText).attrs({tag: 'h4'})``;

export const CardDescription = styled(Text.bodySmallRegular)`
    opacity: 0.6;
    margin: 24px 0 auto;
    min-height: 220px;
    white-space: pre-line;
`;

export const CardLink = styled(Link)`
    svg {
        margin-left: 8px;
        position: relative;
        top: 2px;
    }
`;

export const MoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${above.phone} {
        max-width: 260px;
    }

    a {
        text-align: left;
        ${fonts.h7Regular}
    }
`;

export const MoreTitle = styled(Text.h6Regular)`
    z-index: 1;
`;

const rotate = keyframes`
0% {
    transform: rotateZ(45deg) rotateY(0deg);
}
50% {
    transform: rotateZ(45deg) rotateY(180deg);

}
100% {
    transform: rotateZ(45deg) rotateY(360deg);
}
`;

export const RotatingLogoContainer = styled(Motion.div)`
    position: absolute;
    --width: 80vw;
    width: var(--width);
    bottom: calc(100% + 30px);
    left: calc(50% - var(--width) / 2);
    border-radius: 40%;
    overflow: hidden;
    transform: rotateZ(45deg) rotateY(0deg);
    animation: ${rotate} 10s linear infinite;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, transparent, rgba(255, 255, 255, 1));
    }

    ${above.phone} {
        --width: 400px;
    }

    ${above.laptop} {
        --width: 450px;
        bottom: calc(100% - 90px);
    }
`;

export const Logo = styled(DomeIconSvg)`
    color: var(--dark-grey);
    opacity: 0.8;
`;
