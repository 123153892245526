import styled, {css} from 'styled-components';

import Text from '~components/atoms/Text';
import {BulletListContainer} from '~components/molecules/BulletList/styles';
import {above, below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';
import {ImageAndTextSectionCustomPadding} from '~types/graphql/ImageAndTextSection';

type ImageContainerProps = {
    height: number;
};

export const ImageContainer = styled.div<ImageContainerProps>`
    ${above.laptop} {
        height: ${({height}) => `${height}px`};
    }
    ${below.laptop} {
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const Title = styled(Text.bodyRegular).attrs({tag: 'h6'})`
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 12px;
`;

export const Description = styled(Text.bodyRegular)`
    color: rgba(0, 0, 0, 0.5);
    margin-top: 24px;
`;

export const Wrapper = styled.div`
    display: flex;
`;

export const FlexItem = styled.div`
    flex: 1;
    max-width: 590px;
`;

export const TextContainer = styled.div`
    max-width: 496px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 107px;
    ${below.desktop} {
        margin: 0;
    }

    .h3 {
        margin: 4px 0 16px 0;
    }

    ${Description} {
        margin-bottom: 32px;
    }

    ${BulletListContainer} {
        margin-bottom: 32px;
    }
`;

export const LinkIcon = styled.img`
    margin-left: 8px;
    max-height: 28px;
`;

type ImageAndTextContainerProps = {
    order: 'imageFirst' | 'textFirst' | null;
    background: SectionBackground;
    customPadding: ImageAndTextSectionCustomPadding | null;
};

export const ImageAndTextContainer = styled.section<ImageAndTextContainerProps>`
    padding: ${({customPadding}) =>
        customPadding?.top || customPadding?.bottom
            ? `${customPadding.top || 80}px var(--h-padding) ${
                  customPadding.bottom || 80
              }px var(--h-padding)`
            : '80px var(--h-padding)'};

    ${below.tablet} {
        padding: ${({customPadding}) =>
            customPadding?.mobileTop || customPadding?.mobileBottom
                ? `${customPadding.mobileTop || 80}px var(--h-padding) ${
                      customPadding.mobileBottom || 80
                  }px var(--h-padding)`
                : '80px var(--h-padding)'};
    }

    ${above.laptop} {
        display: grid;
        grid-template-rows: unset;
        grid-template-columns: ${({order}) =>
            order === 'imageFirst'
                ? '1fr minmax(1px, 603px)'
                : 'minmax(1px, 496px) 1fr'};
        column-gap: 128px;
        ${({order}) =>
            order === 'textFirst'
                ? css`
                      ${ImageContainer} {
                          grid-area: 1/2;

                          img,
                          video {
                              object-position: right;
                          }

                          ${above.desktop} {
                              margin-right: 107px;
                          }
                      }
                  `
                : css`
                      ${ImageContainer} {
                          img,
                          video {
                              object-position: left;
                          }
                      }
                  `}
    }

    ${below.laptop} {
        width: 100%;
        display: flex;
        margin: 0 auto;
        gap: 48px;
        flex-direction: ${({order}) =>
            order === 'imageFirst' ? 'column-reverse' : 'column-reverse'};
    }

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
                ${Title} {
                    color: white;
                    opacity: 0.6;
                }
                ${Description} {
                    color: white;
                    opacity: 0.6;
                }
            `;
        }
    }}
`;
