import styled from 'styled-components';

export const PlayerContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;

    video {
        max-width: 100%;
    }
`;

export const MuteBtn = styled.button`
    position: absolute;
    right: 24px;
    bottom: 20px;
    z-index: 2;
    width: 28px;
    height: 28px;
`;
