import {useScopeTranslation} from '@everdome_io/next-js-helper';

import useCountdown from '~hooks/useCountdown';
import {GlobalComponentsQueryEventCountdown} from '~types/graphql/GlobalComponentsQuery';
import {padString} from '~utils/padString';

import {
    ButtonLink,
    CardTitle,
    CountdownContainer,
    Description,
    EventCardContainer,
    Label,
    Title,
    Value,
} from './styles';

type EventCardProps = GlobalComponentsQueryEventCountdown['data']['attributes'] & {
    cardTitle?: string | null;
};

const EventCard = ({
    cardTitle,
    date,
    description,
    title,
    link,
    linkCompleted,
}: EventCardProps) => {
    const t = useScopeTranslation('common', 'countdown');
    const {days, hours, min, sec, timeLeft} = useCountdown(date);

    const linkToShow =
        timeLeft === null ? link : timeLeft <= 0 ? linkCompleted ?? link : link;

    return (
        <EventCardContainer>
            <CardTitle>{cardTitle}</CardTitle>
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
            {date && (
                <CountdownContainer>
                    {(days === null || days > 0) && (
                        <>
                            <Value>{padString(days)}</Value>
                            <Label>{t('days', {count: days ?? 2})}</Label>
                        </>
                    )}
                    <Value>{padString(hours)}</Value>
                    <Label>{t('hours', {count: hours ?? 2})}</Label>
                    <Value>{padString(min)}</Value>
                    <Label>{t('min', {count: min ?? 2})}</Label>
                    {days === 0 && (
                        <>
                            <Value>{padString(sec)}</Value>
                            <Label>{t('sec', {count: sec ?? 2})}</Label>
                        </>
                    )}
                </CountdownContainer>
            )}

            {linkToShow && <ButtonLink link={linkToShow} />}
        </EventCardContainer>
    );
};

export default EventCard;
