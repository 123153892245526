import {useMemo} from 'react';

import {ImagesAndVideosSectionGalleryMedias} from '~types/graphql/ImagesAndVideosSection';

export default function useSliceMedia(
    medias: ImagesAndVideosSectionGalleryMedias[],
    framed: boolean,
) {
    return useMemo(() => {
        return medias.slice(0, framed ? medias.length : 1);
    }, [medias, framed]);
}
