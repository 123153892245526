import styled, {css} from 'styled-components';

import Text from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';
import {FeaturesSectionCustomPadding} from '~types/graphql/FeaturesSection';

type FeaturesSectionContainerProps = {
    background: SectionBackground;
    customPadding: FeaturesSectionCustomPadding | null;
};

export const FeaturesSectionContainer = styled.div<FeaturesSectionContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 40px;

    padding: ${({customPadding}) =>
        customPadding?.top || customPadding?.bottom
            ? `${customPadding.top || 80}px var(--h-padding) ${
                  customPadding.bottom || 80
              }px var(--h-padding)`
            : '80px var(--h-padding)'};

    ${below.tablet} {
        padding: ${({customPadding}) =>
            customPadding?.mobileTop || customPadding?.mobileBottom
                ? `${customPadding.mobileTop || 80}px var(--h-padding) ${
                      customPadding.mobileBottom || 80
                  }px var(--h-padding)`
                : '80px var(--h-padding)'};
    }

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
            `;
        }
    }}
`;

export const SectionName = styled.div<{align: 'center' | 'left' | null}>`
    ${above.phone} {
        text-align: ${({align}) => (align ? align : 'center')};
    }
`;

export const FeaturesContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
    gap: 48px;
    justify-content: space-between;
`;

export const FeatureBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    width: 100%;
`;

export const Icon = styled.img`
    max-width: 32px;
    max-height: 32px;
`;

export const Description = styled(Text.bodyRegular)<{opacity: number}>`
    opacity: ${({opacity}) => (opacity ? opacity : '0.6')};
    a {
        text-decoration: none;
        font-weight: 300;
    }
`;
