import {imageProps} from '~utils/imagePropsReplaceDomain';
import RichText from '~components/atoms/RichText';
import Text from '~components/atoms/Text';
import {TestimonialsSectionTestimonials} from '~types/graphql/TestimonialsSection';

import {
    AuthorImage,
    CompanyImage,
    CompanyInfo,
    ImageContainer,
    TestimonialContainer,
    TestimonialContent,
} from './styles';

const Testimonial = ({
    image,
    text,
    author,
    company,
    companyLogo,
}: TestimonialsSectionTestimonials) => {
    return (
        <TestimonialContainer>
            <ImageContainer>
                <AuthorImage {...imageProps(image)} />
            </ImageContainer>
            <TestimonialContent>
                <div>
                    <RichText>{text}</RichText>
                </div>
                <CompanyInfo>
                    <Text.h7Regular>{`-${author}, ${company}`}</Text.h7Regular>
                    <CompanyImage {...imageProps(companyLogo)} />
                </CompanyInfo>
            </TestimonialContent>
        </TestimonialContainer>
    );
};

export default Testimonial;
