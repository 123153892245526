import {imageProps} from '~utils/imagePropsReplaceDomain';
import ThinDownArrow from '~assets/icons/arrow-down-thin.svg';
import {ForBrandsSection as ForBrandsSectionProps} from '~types/graphql/ForBrandsSection';
import ImageOrVideo from '~components/atoms/ImageOrVideo';
import PageLink from '~components/atoms/PageLink';
import {useBreakpoints} from '~components/providers/BreakpointsProvider';

import {
    BackgroundTitle,
    ForBrandsContainer,
    TileIcon,
    TileContainer,
    TilesContainer,
    TileTitle,
    TileDescription,
    CtaContainer,
    TestimonialContainer,
    PrimaryAssetContainer,
    TestimonialText,
    BottomContainer,
    Author,
    Logo,
    SecondaryAssetContainer,
    CtaTitle,
    TestimonialsContainer,
    MainColumnContainer,
} from './styles';

const ForBrandsSection = ({
    title,
    ctaTitle,
    ctaLink,
    tiles,
    brandsTestimonials,
}: ForBrandsSectionProps) => {
    const {laptop} = useBreakpoints();

    return (
        <>
            <ForBrandsContainer id="for-brands">
                <BackgroundTitle
                    data-scroll
                    data-scroll-sticky
                    data-scroll-target="#for-brands"
                >
                    {title}
                </BackgroundTitle>
                <TilesContainer>
                    {tiles.map(({description, icon, title, link, theme}) => (
                        <TileContainer theme={theme} key={title}>
                            <TileIcon {...imageProps(icon)} />
                            <TileTitle>{title}</TileTitle>
                            <TileDescription>{description}</TileDescription>
                            {link && (
                                <PageLink link={{...link, type: 'withArrow'}} />
                            )}
                        </TileContainer>
                    ))}
                </TilesContainer>
                <CtaContainer
                    {...(laptop
                        ? {
                              'data-scroll': true,
                              'data-scroll-sticky': true,
                              'data-scroll-target': '#for-brands',
                          }
                        : {})}
                >
                    {ctaTitle && <CtaTitle>{ctaTitle}</CtaTitle>}
                    {ctaLink && (
                        <>
                            <ThinDownArrow />
                            <PageLink link={ctaLink} />
                        </>
                    )}
                </CtaContainer>
            </ForBrandsContainer>
            {brandsTestimonials && (
                <TestimonialsContainer>
                    {brandsTestimonials.map(
                        (testimonial, i) =>
                            testimonial && (
                                <TestimonialContainer
                                    key={`${testimonial.text.slice(0, 10)}_${i}`}
                                >
                                    {testimonial.primaryAsset && (
                                        <PrimaryAssetContainer>
                                            <ImageOrVideo
                                                imageOrVideo={
                                                    testimonial.primaryAsset
                                                }
                                                playerProps={{
                                                    allowUnmute: false,
                                                }}
                                            />
                                        </PrimaryAssetContainer>
                                    )}
                                    <MainColumnContainer>
                                        <TestimonialText>
                                            {testimonial.text}
                                        </TestimonialText>
                                        <BottomContainer>
                                            <div>
                                                <Author>{testimonial.author}</Author>
                                                {testimonial.logo?.data && (
                                                    <Logo
                                                        {...imageProps(
                                                            testimonial.logo,
                                                        )}
                                                    />
                                                )}
                                            </div>
                                            <SecondaryAssetContainer>
                                                <ImageOrVideo
                                                    imageOrVideo={
                                                        testimonial.secondaryAsset
                                                    }
                                                    playerProps={{
                                                        allowUnmute: false,
                                                    }}
                                                />
                                            </SecondaryAssetContainer>
                                        </BottomContainer>
                                    </MainColumnContainer>
                                </TestimonialContainer>
                            ),
                    )}
                </TestimonialsContainer>
            )}
        </>
    );
};

export default ForBrandsSection;
