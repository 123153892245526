import {imageProps} from '~utils/imagePropsReplaceDomain';
import InfiniteAnimatedSlider from '~components/atoms/InfiniteAnimatedSlider';
import Text from '~components/atoms/Text';
import {LogosSection as LogosSectionProps} from '~types/graphql/LogosSection';

import {
    Image,
    Logo,
    LogosSectionContainer,
    RowContainer,
    SliderContainer,
} from './styles';

const LogosSection = ({sectionName, logos}: LogosSectionProps) => {
    return (
        <LogosSectionContainer>
            {sectionName && <Text.h4Regular>{sectionName}</Text.h4Regular>}
            <SliderContainer>
                <InfiniteAnimatedSlider
                    direction="left"
                    pixelsPerSecond={50}
                    childrenMultiplier={5}
                >
                    <RowContainer>
                        {logos?.map((item, index) => (
                            <Logo key={index} href={item?.url as string}>
                                <Image {...imageProps(item?.logo)} />
                            </Logo>
                        ))}
                    </RowContainer>
                </InfiniteAnimatedSlider>
            </SliderContainer>
        </LogosSectionContainer>
    );
};

export default LogosSection;
