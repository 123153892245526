import React from 'react';

import {If} from '~components/atoms/If';
import RichText from '~components/atoms/RichText';
import {QuoteQuery} from '~types/graphql/QuoteQuery';

import {Author, StyledQuoteSection} from './styles';

const QuoteSection = ({
    author,
    content,
    quoteBackground,
    customPadding,
}: QuoteQuery) => {
    return (
        <StyledQuoteSection
            background={quoteBackground}
            customPadding={customPadding}
        >
            <RichText tag="p">{content}</RichText>
            <If condition={author}>
                <Author>{author}</Author>
            </If>
        </StyledQuoteSection>
    );
};

export default QuoteSection;
