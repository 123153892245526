import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

// @ts-ignore
const startInterval: typeof window.setInterval = (callback, interval) => {
    callback();
    return setInterval(callback, interval);
};

export default function useCountdown(date: string | Date | number) {
    const [timer, setTimer] = useState<
        Record<'days' | 'hours' | 'min' | 'sec' | 'timeLeft', number | null>
    >({
        days: null,
        hours: null,
        min: null,
        sec: null,
        timeLeft: null,
    });

    useEffect(() => {
        if (date) {
            const interval = startInterval(() => {
                const duration = dayjs.duration(dayjs(date).diff(dayjs()));
                if (duration.asSeconds() <= 0) {
                    setTimer({days: 0, hours: 0, min: 0, sec: 0, timeLeft: 0});

                    return;
                }

                setTimer({
                    days: duration.days(),
                    hours: duration.hours(),
                    min: duration.minutes(),
                    sec: duration.seconds(),
                    timeLeft: duration.asMilliseconds(),
                });
            }, 1000);

            return () => window.clearInterval(interval);
        }
    }, [date]);

    return timer;
}
