import {ReactNode, useMemo} from 'react';

import {ScrollAnimationContainer} from './styles';

const AnimateElementScroll = ({children}: {children: ReactNode}) => {
    const copies = useMemo(
        () =>
            Array(20)
                .fill(0)
                .map(() => children),
        [children],
    );

    return (
        <ScrollAnimationContainer
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="5"
            data-scroll-position="left"
        >
            {copies}
        </ScrollAnimationContainer>
    );
};

export default AnimateElementScroll;
