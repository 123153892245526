import {imageProps} from '~utils/imagePropsReplaceDomain';
import {ImageAndTextSectionList} from '~types/graphql/ImageAndTextSection';

import {BulletListContainer, BulletListItem} from './styles';

type BulletListProps = {
    list: ImageAndTextSectionList | null;
};

const BulletList = ({list}: BulletListProps) => {
    return (
        <BulletListContainer>
            {list?.listItems.map((item) => (
                <BulletListItem key={item.text}>
                    <img {...imageProps(list.icon)} />
                    <div>{item.text}</div>
                </BulletListItem>
            ))}
        </BulletListContainer>
    );
};

export default BulletList;
