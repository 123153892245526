import {useScopeTranslation} from '@everdome_io/next-js-helper';
import classNames from 'classnames';
import {TFunction} from 'next-i18next';
import {useForm} from 'react-hook-form';
import {Trans} from 'react-i18next';

import {Form} from '~components/atoms/Form';
import {TextInput} from '~components/atoms/Form/TextInput';
import useFormField, {defaultValues} from '~molecules/ContactForm/useFormField';

import {Button, Container, Terms} from './styles';

export const ContactForm = () => {
    const t = useScopeTranslation('contact-form');

    const {register, handleSubmit, formState, reset, setError} = useForm({
        mode: 'onBlur',
        defaultValues,
    });
    const {fields, status, onSubmit} = useFormField(reset, setError);

    return (
        <Container>
            <Form>
                {fields.map(({name, required, registerOptions, span, props}) => (
                    <TextInput
                        key={name}
                        {...(t(name, {
                            returnObjects: true,
                        }) as unknown as {label: string; placeholder: string})}
                        // @ts-ignore
                        error={formState.errors[name]?.message}
                        fieldClassName={classNames(span === 'half' && 'halfSpan')}
                        {...props}
                        {...register(
                            // @ts-ignore,
                            name as string,
                            {
                                required:
                                    required && (t('errors.required') as string),
                                ...registerOptions,
                            },
                        )}
                    />
                ))}

                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={status !== 'ready'}
                >
                    {t(`submitBtn.${status}`)}
                </Button>

                <Terms>
                    <Trans
                        t={t as TFunction}
                        ns="contact-form"
                        i18nKey="acceptTerms"
                        components={[
                            <a
                                href={'https://everdome.io/terms'}
                                target={'_blank'}
                            />,

                            <a
                                href={'https://everdome.io/privacy-policy'}
                                target={'_blank'}
                            />,
                        ]}
                    />
                </Terms>
            </Form>
        </Container>
    );
};
