import styled, {css} from 'styled-components';

import {fonts} from '~components/atoms/Text';

export const Field = styled.div`
    width: 100%;
    position: relative;
    text-align: left;
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 2px;
    ${fonts.bodySmallRegular}
`;

const textInputCss = css`
    border: none;
    width: 100%;
    border-radius: 6px;
    background: var(--grey);
    color: var(--black);
    padding: 13px 15px;
    transition: border-color ease-in-out 0.2s, color ease-in-out 0.2s;
    ${fonts.bodyRegular}

    &::placeholder {
        color: #717172;
    }

    :focus {
        outline: none;
    }
`;

export const Input = styled.input`
    ${textInputCss}
`;

export const TextArea = styled.textarea`
    ${textInputCss}
    vertical-align: top;
    resize: vertical;
    max-height: 800px;

    &::-webkit-scrollbar-track {
        border-radius: 0 8px 8px 0;
    }
`;

export const ErrorText = styled.span`
    width: 100%;
    display: block;
    min-height: 16px;
    max-height: 16px;
    text-align: right;
    ${fonts.bodySmallRegular}
    color: var(--orange);
`;
