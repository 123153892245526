import {ImgHTMLAttributes, useEffect, useRef} from 'react';
import {useLocomotiveScroll} from 'react-locomotive-scroll';
import Scroll from 'locomotive-scroll';

import {AnimatedImageWrapper} from './styles';

type AnimatedImageProps = ImgHTMLAttributes<HTMLImageElement> & {
    animate?: boolean;
    rounded?: boolean;
    multiplier?: number;
};

const AnimatedImage = ({
    animate = true,
    rounded = true,
    multiplier = 0.25,
    ...props
}: AnimatedImageProps) => {
    const ref = useRef<HTMLImageElement>(null);
    const {scroll, isReady} = useLocomotiveScroll();

    useEffect(() => {
        if (isReady && animate) {
            if (scroll) {
                (scroll as Scroll).on('scroll', ({currentElements}) => {
                    const scrollElement =
                        currentElements &&
                        ref.current &&
                        Object.values(currentElements).find(
                            ({el}) => el === ref.current,
                        );

                    if (scrollElement) {
                        const progress = scrollElement.progress;

                        if (progress) {
                            const scale = 1 + progress * multiplier;
                            ref.current.style.transform = `scale(${scale})`;
                        }
                    }
                });
            }
        }
    }, [isReady]);

    if (animate) {
        return (
            <AnimatedImageWrapper rounded={rounded}>
                <img
                    {...props}
                    ref={ref}
                    style={{transformOrigin: 'center 80%'}}
                    data-scroll
                />
            </AnimatedImageWrapper>
        );
    }

    return <img {...props} />;
};

export default AnimatedImage;
