import {ReactNode, useEffect, useRef, useState, VideoHTMLAttributes} from 'react';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import SpeakerIcon from '~assets/icons/speaker.svg';
import SpeakerMutedIcon from '~assets/icons/speaker-muted.svg';
import {ImageQuery} from '~types/graphql/ImageQuery';

import {MuteBtn, PlayerContainer} from './styles';

export type PlayerProps = {
    poster?: ImageQuery;
    className?: string;
    children?: ReactNode;
    allowUnmute?: boolean;
} & (
    | {
          video: ImageQuery;
          videoUrl?: null | undefined;
      }
    | {
          videoUrl: string;
          video?: null | undefined;
      }
) &
    Pick<
        VideoHTMLAttributes<HTMLVideoElement>,
        'autoPlay' | 'playsInline' | 'muted' | 'loop' | 'preload'
    >;

const Player = ({
    video,
    videoUrl,
    poster,
    className,
    children,
    allowUnmute = true,
    autoPlay = true,
    playsInline = true,
    muted = true,
    loop = true,
    preload = 'auto',
    ...rest
}: PlayerProps) => {
    const playerRef = useRef<HTMLVideoElement>(null);
    const [isMuted, setIsMuted] = useState(true);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.muted = isMuted;
        }
    }, [isMuted]);

    return (
        <PlayerContainer className={className}>
            <video
                {...(videoUrl ? {src: videoUrl} : imageProps(video))}
                // @ts-ignore
                poster={poster ? imageProps(poster).src : ''}
                autoPlay={autoPlay}
                playsInline={playsInline}
                muted={muted}
                loop={loop}
                preload={preload}
                {...rest}
                ref={playerRef}
            />
            {children}
            {allowUnmute && (
                <MuteBtn onClick={() => setIsMuted(!isMuted)}>
                    {isMuted ? <SpeakerMutedIcon /> : <SpeakerIcon />}
                </MuteBtn>
            )}
        </PlayerContainer>
    );
};

export default Player;
