import {GetStaticPaths, GetStaticProps} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import fetchPageData, {PageDataPropsType} from '~api/fetchPageData';
import Layout from '~components/organisms/Layout';
import Sections from '~components/sections';
import fetchPagesList from '~api/fetchPagesList';

const Page = ({articles, sections, ...rest}: PageDataPropsType) => {
    return (
        <Layout {...rest}>
            <Sections sections={sections} eventCountdown={rest.eventCountdown} />
        </Layout>
    );
};

export const getStaticProps: GetStaticProps<PageDataPropsType> = async (ctx) => {
    const i18n = ctx.locale ? await serverSideTranslations(ctx.locale) : {};

    return await fetchPageData(
        ctx,
        {
            revalidate: 1,
        },
        i18n,
    );
};

export const getStaticPaths: GetStaticPaths = async () => {
    const pages = await fetchPagesList();

    return {
        paths: pages.map((params) => ({params})),
        fallback: true,
    };
};

export default Page;
