import styled from 'styled-components';

import {Label} from '~components/atoms/Form/TextInput/styles';
import {StyledLink} from '~components/atoms/PageLink/styles';
import Text, {fonts} from '~components/atoms/Text';
import {above} from '~config/breakpoints';

export const Container = styled.div`
    width: 100%;
    padding: 48px 32px;
    border: 1px solid rgba(23, 24, 26, 0.8);
    border-radius: 8px;
    background: var(--white);

    ${above.laptop} {
        padding: 40px 52.5px;
    }

    ${StyledLink} {
        width: 100%;
    }

    form {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0 16px;

        & > * {
            grid-column: 1 / span 2;
        }

        .halfSpan {
            grid-column: span 1;
            align-self: flex-end;

            ${Label} {
                white-space: nowrap;
            }
        }
    }
`;

export const Terms = styled(Text.bodySmallRegular)`
    opacity: 0.6;
    margin-top: 16px;

    a {
        ${fonts.bodySmallBold}
    }
`;

export const Button = styled.button`
    margin-top: 24px;
    width: 100%;
    padding: 9px 24px;
    background: var(--black);
    color: var(--white);
    text-align: center;
    border: 1px solid var(--black);
    border-radius: 24px;
    ${fonts.h7Regular}
    transition: all 600ms ease-in-out;

    :hover {
        background: var(--white);
        border-color: var(--black);
        color: var(--black);
    }
`;
