import {useMemo} from 'react';
import {FlattenSimpleInterpolation} from 'styled-components';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import {HeroSection as HeroSectionQuery} from '~types/graphql/HeroSection';
import {If} from '~components/atoms/If';
import RichText from '~components/atoms/RichText';
import {MarginWrapper} from '~components/atoms/MarginWrapper/styles';
import {fonts} from '~components/atoms/Text';

import {
    ButtonFilled,
    ButtonsWrapper,
    Content,
    Description,
    HeroSectionContainer,
    Image,
    ImagesContainer,
    Name,
    TextContainer,
    Title,
} from './styles';

interface StylesMap {
    h1: FlattenSimpleInterpolation;
    h2: FlattenSimpleInterpolation;
    h3: FlattenSimpleInterpolation;
    h4: FlattenSimpleInterpolation;
    h5: FlattenSimpleInterpolation;
    h6: FlattenSimpleInterpolation;
    h7: FlattenSimpleInterpolation;
}

const stylesMap: StylesMap = {
    h1: fonts.h1Regular,
    h2: fonts.h2Regular,
    h3: fonts.h3Regular,
    h4: fonts.h4Regular,
    h5: fonts.h5Regular,
    h6: fonts.h6Regular,
    h7: fonts.h7Regular,
};

type HeroSectionProps = HeroSectionQuery;

const HeroSection = ({
    name,
    title,
    description,
    image,
    icon,
    buttons,
    heroBackground,
    descriptionColor,
}: HeroSectionProps) => {
    const heading = useMemo(() => {
        const tag = title.match(/class="(h\d)/);
        const defaultStyle = fonts.h1Regular;
        if (tag) {
            const heading = tag[1];

            return heading in stylesMap
                ? stylesMap[heading as keyof StylesMap]
                : defaultStyle;
        }
        return defaultStyle;
    }, [title]);

    return (
        <HeroSectionContainer background={heroBackground}>
            <Content>
                <TextContainer>
                    <If condition={name}>
                        <Name>{name}</Name>
                    </If>
                    <If condition={title}>
                        <MarginWrapper>
                            <Title headingCss={heading}>
                                <RichText>{title}</RichText>
                            </Title>
                        </MarginWrapper>
                    </If>
                    <If condition={description}>
                        <Description color={descriptionColor}>
                            <RichText>{description}</RichText>
                        </Description>
                    </If>
                    <If condition={icon.data}>
                        <img {...imageProps(icon)} />
                    </If>
                    <If condition={buttons?.length !== 0}>
                        <ButtonsWrapper>
                            {buttons?.map((item, index) => (
                                <ButtonFilled
                                    key={index}
                                    isDisabled={false}
                                    href={item?.url || ''}
                                    target={item?.target}
                                >
                                    <img {...imageProps(item?.icon)} />
                                    {item?.text}
                                </ButtonFilled>
                            ))}
                        </ButtonsWrapper>
                    </If>
                </TextContainer>
                <If condition={image?.data}>
                    <ImagesContainer>
                        <Image {...imageProps(image)} alt="" />
                    </ImagesContainer>
                </If>
            </Content>
        </HeroSectionContainer>
    );
};

export default HeroSection;
