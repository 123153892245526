import {useState} from 'react';

import Link from '~components/atoms/Link';
import PageLink from '~components/atoms/PageLink';
import VerticalSectionName from '~components/atoms/Text/VerticalSectionName';
import {ForPlayersSection as ForPlayersSectionProps} from '~types/graphql/ForPlayersSection';
import ImageOrVideo from '~components/atoms/ImageOrVideo';
import Motion from '~components/atoms/Motion';

import {
    ForPlayersContainer,
    ItemContainer,
    ItemsContainer,
    ItemTitle,
    ItemText,
    Title,
    LinksContainer,
    AssetsContainer,
    ImageOrVideoWrapper,
} from './styles';

const ForPlayersSection = ({
    sectionName,
    title,
    assets,
    links,
    items,
}: ForPlayersSectionProps) => {
    const [expandedItem, setExpandedItem] = useState(0);

    return (
        <ForPlayersContainer>
            <VerticalSectionName>{sectionName}</VerticalSectionName>
            <Title>{title}</Title>
            <ItemsContainer layout mobile>
                {items.map(({question, answer, link}, i) => (
                    <ItemContainer
                        key={question}
                        onClick={() => setExpandedItem(i)}
                        animate={{
                            opacity: expandedItem === i ? 1 : 0.4,
                        }}
                        mobile
                        layout
                    >
                        <ItemTitle>{question}</ItemTitle>
                        <ItemText
                            as={Motion.p}
                            animate={
                                expandedItem === i
                                    ? {height: 'auto'}
                                    : {height: '0px'}
                            }
                            style={{overflow: 'hidden'}}
                            layout
                            transition={{ease: 'easeInOut'}}
                            mobile
                        >
                            <span>{answer}</span>
                            {link && <PageLink link={link} />}
                        </ItemText>
                    </ItemContainer>
                ))}
            </ItemsContainer>
            <LinksContainer>
                <AssetsContainer>
                    {assets?.map((imageOrVideo, i) => (
                        <ImageOrVideoWrapper key={i}>
                            <ImageOrVideo
                                imageOrVideo={imageOrVideo}
                                playerProps={{allowUnmute: false}}
                            />
                        </ImageOrVideoWrapper>
                    ))}
                </AssetsContainer>
                {links?.map(
                    (link) =>
                        link && (
                            <Link link={link} key={link.text}>
                                <span>{'→'}</span>
                                {'\x0a'}
                                {link.text}
                            </Link>
                        ),
                )}
            </LinksContainer>
        </ForPlayersContainer>
    );
};

export default ForPlayersSection;
