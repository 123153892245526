import {If} from '~components/atoms/If';
import {RoadmapSection as RoadmapSectionProps} from '~types/graphql/RoadmapSection';
import DoneIcon from '~assets/icons/checkmark.svg';
import UnDoneIcon from '~assets/icons/minus.svg';
import Text from '~components/atoms/Text';

import {
    FlexElement,
    ListContainer,
    ListContent,
    ListElement,
    RoadmapContainer,
    RoadmapsContainer,
    RoadmapSectionContainer,
    TitleContainer,
} from './styles';

const RoadmapSection = ({
    sectionName,
    lists,
    roadmapBackground,
}: RoadmapSectionProps) => {
    return (
        <RoadmapSectionContainer background={roadmapBackground}>
            <If condition={sectionName}>
                <Text.h4Regular>{sectionName}</Text.h4Regular>
            </If>
            <If condition={lists.length !== 0}>
                <RoadmapsContainer>
                    {lists.map((item, index) => (
                        <RoadmapContainer key={index}>
                            <TitleContainer>
                                <Text.h6Regular>{item.listName}</Text.h6Regular>
                                <FlexElement isTitle />
                            </TitleContainer>
                            <ListContainer>
                                <FlexElement />
                                <ListContent>
                                    {item.listItems.map((item, index) => (
                                        <ListElement key={index}>
                                            {item.isDone ? (
                                                <DoneIcon />
                                            ) : (
                                                <UnDoneIcon />
                                            )}
                                            <div>{item.text}</div>
                                        </ListElement>
                                    ))}
                                </ListContent>
                            </ListContainer>
                        </RoadmapContainer>
                    ))}
                </RoadmapsContainer>
            </If>
        </RoadmapSectionContainer>
    );
};

export default RoadmapSection;
