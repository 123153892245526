import styled, {css} from 'styled-components';

import {StyledLink} from '~components/atoms/PageLink/styles';
import Text, {fonts} from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';

export const Icon = styled.img`
    max-height: 20px;
`;

type SeeMoreSectionContainerProps = {
    background: SectionBackground;
};

export const SeeMoreSectionContainer = styled.section<SeeMoreSectionContainerProps>`
    padding: 104px var(--h-padding);
    display: flex;
    flex-direction: column;
    gap: 80px;

    ${below.tablet} {
        padding: 80px var(--h-padding);
        gap: 48px;
    }

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
                ${Line} {
                    border-color: #fff !important;
                }
            `;
        }
    }}
`;

export const SeeMoreContainer = styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
    ${below.tablet} {
        flex-direction: column;
    }
`;

export const SeeMoreElement = styled.div<{imageAndTextGap: boolean}>`
    object-fit: contain;
    position: relative;
    width: 100%;

    ${({imageAndTextGap}) =>
        imageAndTextGap &&
        css`
            display: flex;
            flex-direction: column;
            gap: 32px;
        `}

    img {
        width: 100%;
        height: 277px;
        object-fit: cover;
        border-radius: 8px;
    }

    // @FONTS: there is no H6 in jsx
    /* H6 {
        margin-top: 16px;
    } */

    ${above.tablet} {
        max-width: 290px;
    }
    ${below.phone} {
        max-width: 290px;
    }
    ${StyledLink} {
        position: absolute;
        inset: 0 0 0 0;
        width: auto;
    }
`;

export const MarginWrapper = styled.div`
    margin-top: 16px;
    a {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    ${fonts.h7Regular}
`;

export const Description = styled(Text.bodyRegular)<{color: string}>`
    margin-top: 16px;
    color: ${({color}) => (color ? color : 'black')};
    opacity: ${({color}) => !color && 0.6};
`;

export const TextContainer = styled.div`
    width: 100%;
    ${above.tablet} {
        max-width: 232px;
    }
    ${below.phone} {
        max-width: 232px;
    }
`;

export const ElementTitle = styled(Text.bodyRegular)``;

export const FlexItem = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    ${above.tablet} {
        max-width: 175px;
    }
`;

export const Line = styled.div`
    width: 0.5px;
    height: 100%;
    border: 0.5px dashed #000000;
    ${below.tablet} {
        width: 100%;
        height: 0.5px;
    }
`;

export const TitleWrapper = styled(Text.h3Regular)<{
    mobileAlign: 'center' | 'left' | 'right' | null;
}>`
    align-self: center;
    text-align: center;
    ${below.tablet} {
        ${({mobileAlign}) =>
            mobileAlign
                ? css<{mobileAlign: typeof mobileAlign | null}>`
                      align-self: ${({mobileAlign}) =>
                          mobileAlign === 'left'
                              ? 'flex-start'
                              : mobileAlign === 'right'
                              ? 'flex-end'
                              : mobileAlign};
                      text-align: ${mobileAlign};
                  `
                : css``}
    }
`;
