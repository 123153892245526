import React, {ReactNode} from 'react';

type IfProps = {
    condition: boolean | unknown;
    children: ReactNode;
};

export const If = ({condition, children}: IfProps) => {
    return condition ? <>{children}</> : null;
};
