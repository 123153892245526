import {imageProps} from '@everdome_io/next-js-helper';

import {LauncherPresentationSection as LauncherPresentationSectionProps} from '~types/graphql/LauncherPresentationSection';
import shadow from '~assets/images/launcher-shadow.png';

import {StaticContainer} from './styles';

const LauncherPresentationSection = ({
    launcher,
}: LauncherPresentationSectionProps) => {
    return (
        <StaticContainer>
            <img {...imageProps(launcher)} />
            <img src={shadow.src} />
        </StaticContainer>
    );
};
export default LauncherPresentationSection;
