import React from 'react';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import RichText from '~components/atoms/RichText';
import {TextSection as TextSectionProps} from '~types/graphql/TextSection';
import BulletList from '~components/molecules/BulletList';
import {If} from '~components/atoms/If';
import IconAndTextList from '~components/molecules/IconAndTextList';

import {
    ButtonFilled,
    Content,
    Description,
    FlexItem,
    ImageContainer,
    StyledTextSection,
    Title,
} from './styles';

const TextSection = ({
    textTitle,
    textSectionImage,
    textBackground,
    textSectionContent,
    customPadding,
    list,
    button,
    customIconAndTextList,
}: TextSectionProps) => {
    return (
        <StyledTextSection customPadding={customPadding} background={textBackground}>
            <Title isImageExists={Boolean(textSectionImage?.data)}>
                <RichText>{textTitle}</RichText>
            </Title>
            {!textSectionImage?.data ? (
                <Description>
                    <FlexItem />
                    <Content isListExist={Boolean(list)}>
                        <RichText>{textSectionContent}</RichText>
                        <If condition={list}>
                            <div>
                                <BulletList list={list} />
                            </div>
                        </If>
                        <If condition={customIconAndTextList}>
                            <div>
                                <IconAndTextList list={customIconAndTextList} />
                            </div>
                        </If>
                        {button && (
                            <ButtonFilled
                                isDisabled={false}
                                href={button.url || ''}
                                target={button.target}
                            >
                                <img {...imageProps(button.icon)} />
                                {button.text}
                            </ButtonFilled>
                        )}
                    </Content>
                </Description>
            ) : (
                <ImageContainer>
                    <img {...imageProps(textSectionImage)} />
                </ImageContainer>
            )}
        </StyledTextSection>
    );
};

export default TextSection;
