import React from 'react';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import {If} from '~components/atoms/If';
import RichText from '~components/atoms/RichText';
import {SeeMoreSection as SeeMoreSectionProps} from '~types/graphql/SeeMoreSection';
import Link from '~components/atoms/Link';
import Arrow from '~assets/icons/arrow-right-thin.svg';

import {
    Description,
    ElementTitle,
    FlexItem,
    Line,
    MarginWrapper,
    SeeMoreContainer,
    SeeMoreElement,
    SeeMoreSectionContainer,
    TextContainer,
    TitleWrapper,
} from './styles';

const SeeMoreSection = ({
    sectionName,
    elements,
    seeMoreBackground,
    imageAndTextGap,
    sectionNameAlignMobile,
    elementsDescriptionColor,
}: SeeMoreSectionProps) => {
    return (
        <SeeMoreSectionContainer background={seeMoreBackground}>
            <TitleWrapper mobileAlign={sectionNameAlignMobile}>
                <RichText>{sectionName}</RichText>
            </TitleWrapper>
            <SeeMoreContainer>
                {elements.map((item, index) => (
                    <React.Fragment key={`${item.title}_${index}`}>
                        <SeeMoreElement imageAndTextGap={Boolean(imageAndTextGap)}>
                            <img {...imageProps(item.image)} />
                            <TextContainer>
                                <ElementTitle>
                                    <RichText>{item.title}</RichText>
                                </ElementTitle>
                                <Description color={elementsDescriptionColor}>
                                    <RichText>{item.description}</RichText>
                                </Description>
                                <MarginWrapper>
                                    {item.link && (
                                        <Link link={item.link}>
                                            {item.link.text}
                                            {!item.link.text.includes('Comming') && (
                                                <Arrow />
                                            )}
                                        </Link>
                                    )}
                                </MarginWrapper>
                            </TextContainer>
                        </SeeMoreElement>
                        <If condition={index !== elements.length - 1}>
                            <FlexItem>
                                <Line />
                            </FlexItem>
                        </If>
                    </React.Fragment>
                ))}
            </SeeMoreContainer>
        </SeeMoreSectionContainer>
    );
};

export default SeeMoreSection;
