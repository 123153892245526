import React, {useEffect, useState} from 'react';

const useRefDimensions = (ref: React.RefObject<HTMLDivElement>) => {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (ref.current !== null) {
            const resizeObserver = new ResizeObserver((entries) => {
                setHeight(entries[0].target.clientHeight);
                setWidth(entries[0].target.clientWidth);
            });

            resizeObserver.observe(ref.current);
        }
    }, [ref.current]);

    return {height, width};
};

export default useRefDimensions;
