import {useEffect, useState} from 'react';

import {ArticlesDataListQueryArticlesData} from '~types/graphql/ArticlesDataListQuery';
import {useFetchRecentArticles} from '~api/hooks/useFetchRecentArticles';

export const useRecentArticles = () => {
    const [recentArticles, setRecentArticles] =
        useState<ArticlesDataListQueryArticlesData[]>();
    const fetchRecentArticles = useFetchRecentArticles();
    const fetchData = async () => {
        const data = await fetchRecentArticles();
        setRecentArticles(data);
    };
    useEffect(() => {
        fetchData();
    }, []);
    return recentArticles;
};
