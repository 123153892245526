import styled from 'styled-components';

import {below} from '~config/breakpoints';

export const TestimonialContainer = styled.div`
    height: 496px;
    display: flex !important;
    gap: 120px;

    ${below.tablet} {
        flex-direction: column;
        height: 100%;
        gap: 32px;
    }
`;

export const ImageContainer = styled.div`
    height: 100%;
    padding: 0 104px;

    img {
        max-width: 184px;
        object-fit: cover;
    }

    ${below.tablet} {
        width: 100%;
        height: 360px;
        padding: 0;
    }
`;

export const AuthorImage = styled.img`
    width: 390px;

    ${below.tablet} {
        width: 100%;
    }

    height: 100%;
    object-fit: cover;
`;

export const TestimonialContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${below.tablet} {
        gap: 32px;
    }
`;

export const CompanyInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    color: rgba(0, 0, 0, 0.5);

    ${below.tablet} {
        gap: 32px;
    }
`;

export const CompanyImage = styled.img`
    max-height: 100px;
`;
