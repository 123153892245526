import {imageProps} from '~utils/imagePropsReplaceDomain';
import {ImageAndTextSectionAdditionalList} from '~types/graphql/ImageAndTextSection';

import {BulletListContainer, BulletListItem} from './styles';

type BulletListProps = {
    list: ImageAndTextSectionAdditionalList | null;
};

const AdditionalBulletList = ({list}: BulletListProps) => {
    return (
        <BulletListContainer>
            <div>{list?.text}</div>
            {list?.listItems?.map((item) => (
                <BulletListItem key={item?.text}>
                    <img {...imageProps(list.icon)} />
                    <div>{item?.text}</div>
                </BulletListItem>
            ))}
        </BulletListContainer>
    );
};

export default AdditionalBulletList;
