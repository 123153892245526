import {SectionName} from './styles';

type VerticalSectionNameProps = {
    children: string | null;
    className?: string;
};

const VerticalSectionName = ({children, className}: VerticalSectionNameProps) => {
    return children ? (
        <SectionName className={className}>{children}</SectionName>
    ) : null;
};

export default VerticalSectionName;
