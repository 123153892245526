import {If} from '~components/atoms/If';
import SectionTitle from '~components/atoms/Text/SectionTitle';
import TestimonialsCarousel from '~components/molecules/TestimonialsCarousel';
import {TestimonialsSection as TestimonialsSectionQuery} from '~types/graphql/TestimonialsSection';

import {TestimonialsSectionContainer} from './styles';

const TestimonialsSection = ({
    sectionTitle,
    testimonials,
}: TestimonialsSectionQuery) => {
    return (
        <TestimonialsSectionContainer>
            <If condition={sectionTitle}>
                <SectionTitle>{sectionTitle}</SectionTitle>
            </If>
            <TestimonialsCarousel testimonials={testimonials} />
        </TestimonialsSectionContainer>
    );
};

export default TestimonialsSection;
