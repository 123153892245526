import React, {useCallback} from 'react';
import {useRouter} from 'next/dist/client/router';

import {PageDataPropsType} from '~api/fetchPageData';
import {PageQueryPageDataAttributesContent} from '~types/graphql/PageQuery';

import TestimonialsSection from './TestimonialsSection';
import ImagesAndVideosSection from './ImagesAndVideosSection';
import HeroSection from './HeroSection';
import TextSection from './TextSection';
import FeaturesSection from './FeaturesSection';
import ImageAndTextSection from './ImageAndTextSection';
import SeeMoreSection from './SeeMoreSection';
import QuoteSection from './QuoteSection';
import LogosSection from './LogosSection';
import CallToActionSection from './CallToActionSection';
import FAQSection from './FAQSection';
import RoadmapSection from './RoadmapSection';
import NewsSection from './NewsSection';
import VideoWithOverlaySection from './VideoWithOverlaySection';
import AboutEverdomeSection from './AboutEverdomeSection';
import VideoWithLinksSection from './VideoWithLinksSection';
import ForPlayersSection from './ForPlayersSection';
import StatsSection from './StatsSection';
import ForBrandsSection from './ForBrandsSection';
import ForInvestorsSection from './ForInvestors';
import TitleDescriptionSection from './TitleDescriptionSection';
import ActivitiesSection from './ActivitiesSection';
import FontsTestSection from './FontsTestSection';
import ContactFormSection from './ContactFormSection';
import RequirementsSection from './RequirementsSection';
import StaticImageSection from './StaticImageSection';
import LauncherPresentationSection from './LauncherPresentation';

type SectionsProps = Pick<PageDataPropsType, 'sections' | 'eventCountdown'>;

const Sections = ({sections, eventCountdown}: SectionsProps) => {
    const router = useRouter();

    const renderComponent = useCallback(
        (section: PageQueryPageDataAttributesContent, index: number) => {
            const key = `${index}_${section.__typename}`;
            switch (section.__typename) {
                case 'ComponentSectionsTestimonials': {
                    return <TestimonialsSection key={key} {...section} />;
                }
                case 'ComponentSectionsText': {
                    return <TextSection key={key} {...section} />;
                }
                case 'ComponentSectionsHero': {
                    return <HeroSection key={key} {...section} />;
                }
                case 'ComponentSectionsImagesAndVideos': {
                    return <ImagesAndVideosSection key={key} {...section} />;
                }
                case 'ComponentSectionsFeatures': {
                    return <FeaturesSection key={key} {...section} />;
                }
                case 'ComponentSectionsImageAndText': {
                    return <ImageAndTextSection key={key} {...section} />;
                }
                case 'ComponentSectionsSeeMore': {
                    return <SeeMoreSection key={key} {...section} />;
                }
                case 'ComponentSectionsQuote': {
                    return <QuoteSection key={key} {...section} />;
                }
                case 'ComponentSectionsLogos': {
                    return <LogosSection key={key} {...section} />;
                }
                case 'ComponentSectionsCallToAction': {
                    return <CallToActionSection key={key} {...section} />;
                }
                case 'ComponentSectionsFaq': {
                    return <FAQSection key={key} {...section} />;
                }
                case 'ComponentSectionsRoadmap': {
                    return <RoadmapSection key={key} {...section} />;
                }
                case 'ComponentSectionsNews':
                    return <NewsSection key={key} {...section} />;
                case 'ComponentSectionsVideoWithOverlay':
                    return <VideoWithOverlaySection key={key} {...section} />;
                case 'ComponentSectionsAboutEverdome':
                    return (
                        <AboutEverdomeSection
                            key={key}
                            {...section}
                            eventCountdown={eventCountdown}
                        />
                    );
                case 'ComponentSectionsVideoWithLinks':
                    return <VideoWithLinksSection key={key} {...section} />;
                case 'ComponentSectionsForPlayers':
                    return <ForPlayersSection key={key} {...section} />;
                case 'ComponentSectionsStats':
                    return <StatsSection key={key} {...section} />;
                case 'ComponentSectionsForBrands':
                    return <ForBrandsSection key={key} {...section} />;
                case 'ComponentSectionsForInvestors':
                    return <ForInvestorsSection key={key} {...section} />;
                case 'ComponentSectionsTitleDescription':
                    return <TitleDescriptionSection key={key} {...section} />;
                case 'ComponentSectionsActivities':
                    return <ActivitiesSection key={key} {...section} />;
                case 'ComponentSectionsFontsTest':
                    return <FontsTestSection key={key} {...section} />;
                case 'ComponentSectionsContactForm':
                    return <ContactFormSection key={key} {...section} />;
                case 'ComponentSectionsRequirements':
                    return <RequirementsSection key={key} {...section} />;
                case 'ComponentSectionsStaticImage':
                    return <StaticImageSection key={key} {...section} />;
                case 'ComponentSectionsLauncherPresentation':
                    return <LauncherPresentationSection key={key} {...section} />;
                default:
                    return null;
            }
        },
        [router],
    );

    return <>{(sections || []).map(renderComponent)}</>;
};
export default Sections;
