import Link from '~components/atoms/Link';
import Player from '~components/atoms/Player';
import {VideoWithLinksSection as VideoWithLinksSectionProps} from '~types/graphql/VideoWithLinksSection';
import YoutubeIcon from '~assets/icons/youtube.svg';

import {
    Description,
    LinksContainer,
    LinksTitle,
    LinkWithIcon,
    SectionName,
    Title,
    TitleWrapper,
    VideoContainer,
    VideoWithLinksContainer,
} from './styles';

const VideoWithLinksSection = ({
    sectionName,
    title,
    description,
    linksTitle,
    links,
    video,
}: VideoWithLinksSectionProps) => {
    return (
        <VideoWithLinksContainer>
            <VideoContainer>
                <Player video={video} />
            </VideoContainer>
            <TitleWrapper>
                <SectionName>{sectionName}</SectionName>
                <Title>{title}</Title>
            </TitleWrapper>
            <Description>{description}</Description>
            {links && (
                <LinksContainer>
                    <LinksTitle>{linksTitle}</LinksTitle>
                    {links.map((link) => (
                        <Link
                            link={{
                                ...link,
                                target: '_blank',
                            }}
                            key={link?.text}
                        >
                            <LinkWithIcon>
                                {link?.url?.includes('youtube') && <YoutubeIcon />}
                                {link?.text}
                            </LinkWithIcon>
                        </Link>
                    ))}
                </LinksContainer>
            )}
        </VideoWithLinksContainer>
    );
};

export default VideoWithLinksSection;
