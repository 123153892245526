import styled from 'styled-components';

import {above, below} from '~config/breakpoints';

export const TestimonialsCarouselContainer = styled.div`
    position: relative;

    .slick-arrow {
        display: none !important;
    }

    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
    }

    .slick-slider .slick-list,
    .slick-slider .slick-track {
        transform: translateZ(0);
    }

    .slick-initialized .slick-slide {
        display: block;
    }

    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .slick-list,
    .slick-slider {
        position: relative;
        display: block;
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const Wrapper = styled.div`
    position: relative;
`;

export const ControlButtons = styled.div`
    display: flex;

    ${below.tablet} {
        margin-top: 40px;
        justify-content: space-between;
    }

    ${above.tablet} {
        position: absolute;
        bottom: 0;
        right: 0;
        gap: 40px;
    }
`;
