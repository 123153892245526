import React, {useState} from 'react';

import RichText from '~components/atoms/RichText';
import Text, {fontsConfig, FontStyles, fontStyles} from '~components/atoms/Text';
import {FontsTestSection as FontsTestSectionProps} from '~types/graphql/FontsTestSection';

import {FontsTestSectionContainer, LeftContainer, Separator} from './styles';

const FontsTestSection = ({richText}: FontsTestSectionProps) => {
    const [sampleText, setSampleText] = useState('Land 17.38°N On Auction');
    const [theme, setTheme] = useState('dark');

    return (
        <FontsTestSectionContainer theme={theme}>
            <div>
                <label>{'Sample text: '}</label>
                <input
                    type="text"
                    value={sampleText}
                    onChange={(ev) => setSampleText(ev.target.value)}
                    style={{fontSize: '16px'}}
                />
            </div>
            <div>
                <label>{'Theme: '}</label>
                <select value={theme} onChange={(ev) => setTheme(ev.target.value)}>
                    {['dark', 'light', 'orange', 'strapi'].map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </select>
            </div>
            <LeftContainer>
                {fontStyles.map((fontStyle: FontStyles, i) => {
                    const config = fontsConfig[fontStyle];
                    const nextConfig = fontsConfig[fontStyles[i + 1]];

                    const Component = Text[fontStyle];

                    return (
                        <React.Fragment key={fontStyle}>
                            <Component>{sampleText}</Component>
                            {nextConfig && config.tag !== nextConfig.tag && (
                                <Separator />
                            )}
                        </React.Fragment>
                    );
                })}
            </LeftContainer>
            <RichText tag="div">{richText}</RichText>
        </FontsTestSectionContainer>
    );
};

export default FontsTestSection;
