import {imageProps} from '@everdome_io/next-js-helper';

import RichText from '~components/atoms/RichText';
import {TitleDescriptionSection as TitleDescriptionSectionProps} from '~types/graphql/TitleDescriptionSection';

import {
    AdditionalDescription,
    ButtonFilled,
    Description,
    Title,
    TitleDescriptionContainer,
} from './styles';

const TitleDescriptionSection = ({
    title,
    description,
    button,
    customPadding,
    descriptionColor,
    disableButton,
    additionalDescription,
}: TitleDescriptionSectionProps) => {
    return (
        <TitleDescriptionContainer customPadding={customPadding}>
            <Title>{title}</Title>
            {description && (
                <Description descriptionColor={descriptionColor}>
                    <RichText>{description}</RichText>
                </Description>
            )}
            {additionalDescription && (
                <AdditionalDescription>
                    <RichText>{additionalDescription}</RichText>
                </AdditionalDescription>
            )}
            {button && (
                <ButtonFilled
                    isDisabled={Boolean(disableButton)}
                    href={disableButton ? undefined : `${button.url}`}
                    target={button.target}
                >
                    <img {...imageProps(button.icon)} />
                    {button.text}
                </ButtonFilled>
            )}
        </TitleDescriptionContainer>
    );
};

export default TitleDescriptionSection;
