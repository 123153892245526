import ReactPlayer from 'react-player';
import {useMemo, useRef, useState} from 'react';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import {ImagesAndVideosSectionGalleryMedias} from '~types/graphql/ImagesAndVideosSection';
import Dome from '~assets/icons/dome.svg';
import Everdome from '~assets/icons/everdome.svg';
import VolumeIcon from '~assets/icons/volume.svg';
import VolumeMutedIcon from '~assets/icons/volume-muted.svg';
import useRefDimensions from '~hooks/useRefDimensions';
import {If} from '~components/atoms/If';

import {
    Controls,
    FlexItem,
    Image,
    ImageOrVideoContainer,
    Play,
    RightText,
    Volume,
    Wrapper,
} from './styles';

type ImageOrVideoProps = {
    media: ImagesAndVideosSectionGalleryMedias;
    isFramed?: boolean;
};

const ImageOrVideo = ({media, isFramed}: ImageOrVideoProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const dimensions = useRefDimensions(containerRef);
    const [videoControls, setVideoControls] = useState({
        playing: true,
        muted: true,
    });

    const videoData = useMemo(() => {
        return media.media?.data.attributes.mime.includes('video')
            ? imageProps(media.media)
            : null;
    }, []);

    return (
        <ImageOrVideoContainer contain={media.contain}>
            {media.videoUrl ||
            media.media?.data?.attributes.mime.includes('video') ? (
                <>
                    <ReactPlayer
                        url={videoData ? videoData.src : media.videoUrl || ''}
                        width="100%"
                        height="100%"
                        playing={videoControls.playing}
                        muted={videoControls.muted}
                        loop
                    />
                    <Controls>
                        <Play
                            onClick={() =>
                                setVideoControls({
                                    ...videoControls,
                                    playing: !videoControls.playing,
                                })
                            }
                        />
                        <Volume
                            onClick={() =>
                                setVideoControls({
                                    ...videoControls,
                                    muted: !videoControls.muted,
                                })
                            }
                        >
                            {!isFramed ? (
                                videoControls.muted ? (
                                    <VolumeIcon />
                                ) : (
                                    <VolumeMutedIcon />
                                )
                            ) : null}
                        </Volume>
                    </Controls>
                </>
            ) : (
                <Image {...imageProps(media.media)} />
            )}
            <If condition={!isFramed && media.mediaText}>
                <Wrapper ref={containerRef}>
                    <Dome />
                    <RightText width={dimensions.height}>
                        <span>{media.mediaText}</span>
                        <FlexItem />
                        <Everdome />
                    </RightText>
                </Wrapper>
            </If>
        </ImageOrVideoContainer>
    );
};

export default ImageOrVideo;
