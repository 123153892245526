import styled from 'styled-components';

import Text, {fonts} from '~components/atoms/Text';
import {above} from '~config/breakpoints';

export const CTAContainer = styled.section`
    margin-top: 260px;
    padding: 255px var(--h-padding) 100px;
    display: flex;
    align-items: center;
    background: var(--dark-grey);
    justify-content: center;
    position: relative;
    color: white;
    --white: var(--dark-grey);
    --black: #fff;

    ${above.laptop} {
        margin-top: 280px;
    }
`;

export const CTAContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 650px;
    text-align: center;
    align-items: center;
    z-index: 2;

    a {
        ${fonts.bodyRegular}
        padding-inline: 35px;
    }
`;

export const Description = styled(Text.bodyRegular)`
    opacity: 0.6;
    margin-top: 24px;
    max-width: 600px;
`;

export const Dot = styled.div`
    background: currentColor;
    border-radius: 100%;
    width: 2px;
    height: 2px;
    margin: 32px 0;
`;

export const MarsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
`;

export const Stars = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;

    & > div {
        height: 100%;
    }

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(to top, var(--dark-grey) 20%, transparent 80%);
    }
`;
