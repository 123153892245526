import styled, {css} from 'styled-components';

import {fontsNoBreakpoints} from '~components/atoms/Text';
import {above} from '~config/breakpoints';
import {SectionBackground} from '~types/background';
import {QuoteQueryCustomPadding} from '~types/graphql/QuoteQuery';

type StyledQuoteSectionProps = {
    background: SectionBackground;
    customPadding?: QuoteQueryCustomPadding | null;
};

export const StyledQuoteSection = styled.div<StyledQuoteSectionProps>`
    display: flex;
    flex-direction: column;
    gap: 48px;

    text-align: center;

    .h5Regular {
        font-weight: 300 !important;
    }
    .h6Regular {
        font-weight: 300 !important;
    }

    ${above.laptop} {
        gap: 32px;

        & > * {
            max-width: 1016px;
            margin: 0 auto;
        }
    }

    ${above.tablet} {
        padding: ${({customPadding}) =>
            customPadding?.top || customPadding?.bottom
                ? `${customPadding.top || 160}px 16px ${
                      customPadding.bottom || 160
                  }px 16px`
                : '160px 16px'};
    }

    padding: ${({customPadding}) =>
        customPadding?.mobileTop || customPadding?.mobileBottom
            ? `${customPadding.mobileTop || 80}px 16px ${
                  customPadding.mobileBottom || 80
              }px 16px`
            : '80px 16px'};

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
            `;
        }
    }}
`;

export const Author = styled.p`
    ${fontsNoBreakpoints.bodyRegular}
    line-height: unset;

    opacity: 0.4;

    ${above.laptop} {
        ${fontsNoBreakpoints.h7Regular};
        line-height: unset;
    }
`;
