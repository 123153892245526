import styled from 'styled-components';

import Text from '~components/atoms/Text';

export const BulletListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const BulletListItem = styled(Text.bodyRegular)`
    display: flex;
    gap: 8px;
    align-items: center;
`;
