import styled from 'styled-components';

import {above} from '~config/breakpoints';

export const LogosSectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 80px var(--h-padding);
    align-items: center;
    overflow: hidden;
`;

export const SliderContainer = styled.div`
    --height: 108px;
    height: var(--height);
`;

export const RowContainer = styled.div`
    height: var(--height);
    display: flex;
    align-items: center;
`;

export const Logo = styled.a`
    margin-right: 60px;
    width: 150px;
    height: auto;
    max-height: 42px;
    display: flex;
    align-items: center;
    position: relative;

    ${above.laptop} {
        margin-right: 100px;
    }

    &:not([href]) {
        pointer-events: none;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: scale-down;
`;
