import React, {useRef} from 'react';
import {useFrame} from '@react-three/fiber';
import * as THREE from 'three';
import {isBrowser} from 'react-use/lib/misc/util';

const R = 0.4;
const dA = 0.001;
// const dA = 0.004;

type MarsProps = JSX.IntrinsicElements['mesh'];

const animateTo = (value: number, end: number, factor = 0.05) => {
    return value + (end - value) * factor;
};

const mapTexture = isBrowser
    ? new THREE.TextureLoader().load('/assets/images/mars/mars.jpg')
    : undefined;
const mapBumpMap = isBrowser
    ? new THREE.TextureLoader().load('/assets/images/mars/normal.jpg')
    : undefined;

export default function Mars({...props}: MarsProps) {
    const mars = useRef<THREE.Mesh>(null!);
    const point = useRef<THREE.Mesh>(null!);
    const ring = useRef<THREE.Mesh>(null!);

    useFrame(() => {
        mars.current.scale.setScalar(animateTo(mars.current.scale.x, 1));
        mars.current.position.y = animateTo(mars.current.position.y, -0.1 * R);
        mars.current.rotation.y = (mars.current.rotation.y + dA) % (2 * Math.PI);

        // const t = 0;
        if (ring.current && point.current) {
            const s = 0.223;
            const t = mars.current.rotation.y - 0.21;
            const r = 1;

            point.current?.position.set(
                r * Math.cos(s) * Math.sin(t),
                r * Math.sin(s),
                r * Math.cos(t),
            );

            ring.current?.position.set(
                r * Math.cos(s) * Math.sin(t),
                r * Math.sin(s),
                r * Math.cos(t),
            );
            ring.current.rotation.y = mars.current.rotation.y;
            ring.current.scale.setScalar(animateTo(ring.current.scale.x, 1.1, 0.02));
            if (ring.current.scale.x > 1) {
                ring.current.scale.setScalar(0.2);
            }
        }
    });

    return (
        <>
            <mesh {...props} ref={mars}>
                <sphereGeometry args={[R, 128, 128]} />
                <meshPhongMaterial
                    map={mapTexture}
                    bumpMap={mapBumpMap}
                    bumpScale={0.005}
                />
            </mesh>
        </>
    );
}
