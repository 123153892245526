import styled, {css} from 'styled-components';

import {below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';

type RoadmapSectionContainerProps = {
    background: SectionBackground;
};

export const RoadmapSectionContainer = styled.section<RoadmapSectionContainerProps>`
    padding: 80px var(--h-padding);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;

    ${below.phone} {
        gap: 40px;
    }

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;

                ${ListContainer} {
                    border-color: white;
                }
            `;
        }
    }}
`;

export const RoadmapsContainer = styled.div`
    width: 100%;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 48px;

    ${below.phone} {
        gap: 40px;
        padding: 0;
    }
`;

export const RoadmapContainer = styled.div`
    height: fit-content;
    display: flex;

    ${below.phone} {
        flex-direction: column;
        gap: 24px;
    }
`;

export const TitleContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;

    ${below.phone} {
        justify-content: flex-start;
    }
`;

export const ListContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    border-left: 1px solid black;
`;

export const ListContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

type FlexElementProps = {
    isTitle?: boolean;
};

export const FlexElement = styled.div<FlexElementProps>`
    ${({isTitle}) =>
        isTitle
            ? css`
                  flex: 1;
                  max-width: 122px;
              `
            : css`
                  width: 66px;

                  ${below.phone} {
                      width: 26px;
                  }
              `}
`;

export const ListElement = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    img {
        max-width: 14px;
    }
`;
