import {ErrorBoundary} from 'react-error-boundary';
import UAParser from 'ua-parser-js';

import {ForInvestorsSection as ForInvestorsSectionProps} from '~types/graphql/ForInvestorsSection';
import PageLink from '~components/atoms/PageLink';

import {
    Bow,
    Card,
    CardDescription,
    CardsContainer,
    CardTitle,
    Description,
    DomeIcon,
    ForInvestorsContainer,
    Logo,
    MoreContainer,
    MoreTitle,
    RotatingLogoContainer,
    Title,
    TopRow,
} from './styles';

const ForInvestorsSection = ({
    title,
    description,
    moreLink,
    moreTitle,
    cards,
}: ForInvestorsSectionProps) => {
    return (
        // This section sometimes throws hydration mismatch in various places with dangerouslySetInnerHtml.
        // I don't know why since everything is the same
        <ErrorBoundary fallback={null}>
            <ForInvestorsContainer>
                <Bow />
                <TopRow>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </TopRow>
                <CardsContainer>
                    <RotatingLogoContainer
                        style={
                            new UAParser().getEngine()?.name === 'WebKit'
                                ? {
                                      animation: 'nonde',
                                  }
                                : {}
                        }
                    >
                        <Logo />
                    </RotatingLogoContainer>
                    {cards.map(
                        ({title, description, link, background, domeLogo}, i) => (
                            <Card
                                background={background}
                                link={link}
                                key={`${title}_${i}`}
                            >
                                {domeLogo && <DomeIcon />}
                                <CardTitle>{title}</CardTitle>
                                <CardDescription>{description}</CardDescription>
                                {link && <PageLink link={link} />}
                            </Card>
                        ),
                    )}
                </CardsContainer>
                <MoreContainer>
                    <MoreTitle>
                        {'→'}
                        {'\x0a'}
                        {moreTitle}
                    </MoreTitle>
                    {moreLink && (
                        <PageLink link={moreLink}>
                            <span>
                                {moreLink.text}
                                <br />
                                <b>{moreLink.subText}</b>
                            </span>
                        </PageLink>
                    )}
                </MoreContainer>
            </ForInvestorsContainer>
        </ErrorBoundary>
    );
};

export default ForInvestorsSection;
