import styled, {css} from 'styled-components';

import Motion from '~components/atoms/Motion';
import {above, below} from '~config/breakpoints';
import RichText from '~components/atoms/RichText';
import {StyledLink} from '~components/atoms/PageLink/styles';
import {EventCardContainer} from '~components/molecules/EventCard/styles';
import {fonts} from '~components/atoms/Text';
import {
    underlineActiveCss,
    underlineOnHoverCss,
} from '~components/atoms/UnderlineOnHover/styles';

export const AboutEverdomeSectionContainer = styled.section<{
    theme: 'dark' | 'light';
    showEventCard: boolean;
}>`
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 104px var(--h-padding);
    --event-card-width: 292px;

    ${above.laptop} {
        // above event countdown
        z-index: 11;
    }

    ${({showEventCard}) =>
        showEventCard &&
        css`
            ${above.laptop} {
                padding-right: calc(var(--event-card-width) + 30px);
            }
        `}

    ${({theme = 'dark'}) => {
        switch (theme) {
            case 'dark': {
                return css`
                    --white: var(--dark-grey);
                    --black: #ffffff;
                    background: var(--white);
                    color: var(--black);
                `;
            }
        }
    }}

    ${below.laptop} {
        ${EventCardContainer} {
            position: relative;
            width: 100%;
            inset-inline: unset;
            align-items: flex-start;
            align-content: flex-start;
            bottom: unset;
            top: calc(104px + 40px);
        }
    }
`;

export const TabsRow = styled.div`
    display: grid;
    gap: 24px 16px;
    white-space: nowrap;
    padding-right: 50px;
    justify-self: flex-start;
    justify-content: flex-start;
    scroll-snap-type: x mandatory;

    ${above.laptop} {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        gap: 32px;
        padding-left: 12px;
    }

    ${above.desktop} {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    ${below.laptop} {
        grid-auto-flow: column;
        overflow-x: auto;
        width: calc(100% + var(--h-padding));
        max-width: max-content;
        padding-bottom: 5px;
    }
`;

export const TabButton = styled(Motion.button)<{selected: boolean}>`
    scroll-snap-align: start;
    padding: 5px 16px;
    position: relative;
    min-width: min-content;
    ${fonts.h7Bold}
    text-transform: uppercase;

    ${above.laptop} {
        padding: 5px 30px 7px;
    }

    ${underlineOnHoverCss}

    ${({selected}) => selected && underlineActiveCss}
`;

export const TabContainer = styled(Motion.div)`
    display: grid;
    margin: 60px 0 0 0;
    gap: 32px 80px;
    grid-template-columns: 1fr;

    ${above.laptop} {
        grid-template-columns: auto 1fr;
        margin: 88px 0 0 0;
        gap: 24px 80px;
    }

    ${below.laptop} {
        margin-bottom: 150px;
    }

    ${StyledLink} {
        grid-column: 1;
        text-transform: uppercase;
    }
`;

export const TextContainer = styled(Motion.div)`
    max-width: 496px;
    z-index: 1;
    ${above.laptop} {
        min-height: 550px;
    }

    ${StyledLink} {
        margin-top: 32px;
    }
`;

export const Title = styled(RichText).attrs({tag: 'h2'})`
    max-width: 496px;
    margin-bottom: 24px;
`;

export const Text = styled(RichText).attrs({tag: 'p'})`
    opacity: 0.6;
`;

export const AssetContainer = styled(Motion.div)`
    position: absolute;
    max-width: 500px;
    width: 100vw;
    bottom: calc(100% - 1050px);
    left: 0;
    display: flex;

    ${above.laptop} {
        max-width: 700px;
        width: 45%;
        left: unset;
        right: calc(20% - 170px);
        bottom: 0;
    }

    img,
    video {
        width: 100%;
        max-height: 100%;
        object-fit: scale-down;

        ${above.laptop} {
            min-width: 400px;
        }
    }
`;
