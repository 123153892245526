// import Link from 'next/link';

import {NewsSection as NewsSectionProps} from '~types/graphql/NewsSection';
import ArticleTile from '~molecules/ArticleTile';
import AnimateElementScroll from '~atoms/AnimateElementScroll';
import ArticleTileBig from '~molecules/ArticleTileBig';
import PageLink from '~components/atoms/PageLink';

// import {Routes} from '../../../consts/routes';

import useArticles from './useArticles';
import {
    Container,
    Line,
    OtherArticles,
    SeeMoreContainer,
    Title,
    Wrapper,
} from './styles';

const NewsSection = ({title, seeAllText}: NewsSectionProps) => {
    const {latestArticle, otherArticles} = useArticles();

    if (!latestArticle && !otherArticles?.length) {
        return null;
    }

    return (
        <Wrapper id="direction">
            <AnimateElementScroll>
                <Title>{title}</Title>
            </AnimateElementScroll>
            <Container>
                {latestArticle && <ArticleTileBig article={latestArticle} />}
                <div>
                    <OtherArticles>
                        {otherArticles?.slice(0, 3).map((article) => (
                            <ArticleTile key={article.id} article={article} />
                        ))}
                    </OtherArticles>
                    <SeeMoreContainer>
                        <Line />
                        <PageLink
                            link={{
                                url: 'https://everdome.io/news',
                                type: 'withArrow',
                            }}
                        >
                            {seeAllText}
                        </PageLink>
                    </SeeMoreContainer>
                </div>
            </Container>
        </Wrapper>
    );
};
export default NewsSection;
