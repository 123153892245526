import styled, {css} from 'styled-components';

import Text from '~components/atoms/Text';
import {above} from '~config/breakpoints';

export const ActivitiesContainer = styled.section`
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;

    ${above.laptop} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const ActivityCardContainer = styled.div`
    display: flex;
    padding: 80px 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 519px;

    ${above.laptop} {
        min-height: 877px;
    }
`;

export const BackgroundContainer = styled.div<{
    background: 'dark' | 'white' | 'grey' | 'orange' | null;
}>`
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: -1;

    ${({background}) => {
        switch (background) {
            case 'dark': {
                return css`
                    background-color: var(--dark-grey);
                `;
            }
            case 'grey': {
                return css`
                    background-color: var(--grey);
                `;
            }
            case 'white': {
                return css`
                    background-color: var(--white);
                `;
            }
            case 'orange': {
                return css`
                    background-color: var(--orange);
                `;
            }
        }
    }}
`;

export const CardInner = styled.div<{
    cardTheme: 'dark' | 'white' | 'grey' | 'orange';
}>`
    border-radius: 8px;
    padding: 24px 32px;
    color: var(--dark-grey);
    max-width: 344px;

    ${({cardTheme}) => {
        switch (cardTheme) {
            case 'dark': {
                return css`
                    background: var(--dark-grey);
                    color: var(--white) !important;
                `;
            }
            case 'grey': {
                return css`
                    background: var(--grey);
                `;
            }
            case 'white': {
                return css`
                    background: var(--white);
                `;
            }
            case 'orange': {
                return css`
                    background: var(--orange);
                    color: var(--white) !important;
                `;
            }
        }
    }}
`;

export const Activity = styled(Text.bodySmallBold).attrs({tag: 'h6'})`
    opacity: 0.4;
    text-transform: uppercase;
`;

export const Title = styled(Text.h5Bold)`
    margin: 16px 0 16px;
    min-height: 105px;

    ${above.laptop} {
        margin: 16px 0 24px;
        min-height: 160px;
    }
`;

export const Description = styled(Text.bodySmallRegular)`
    opacity: 0.6;
    min-height: 140px;
`;
