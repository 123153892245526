import styled, {css} from 'styled-components';

import Text from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';
import {TextSectionCustomPadding} from '~types/graphql/TextSection';

type StyledTextSectionProps = {
    background: SectionBackground;
    customPadding: TextSectionCustomPadding | null;
};

export const StyledTextSection = styled.div<StyledTextSectionProps>`
    display: grid;

    grid-template-rows: 1fr;
    row-gap: 24px;

    ${above.laptop} {
        grid-template-rows: unset;
        grid-template-columns: minmax(1px, 496px) 1fr;
        column-gap: 128px;
    }

    padding: ${({customPadding}) =>
        customPadding?.top || customPadding?.bottom
            ? `${customPadding.top || 80}px var(--h-padding) ${
                  customPadding.bottom || 80
              }px var(--h-padding)`
            : '80px var(--h-padding)'};

    ${below.tablet} {
        padding: ${({customPadding}) =>
            customPadding?.mobileTop || customPadding?.mobileBottom
                ? `${customPadding.mobileTop || 80}px var(--h-padding) ${
                      customPadding.mobileBottom || 80
                  }px var(--h-padding)`
                : '80px var(--h-padding)'};
    }

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
            `;
        }
    }}
`;

type TitleProps = {
    isImageExists: boolean;
};

export const Title = styled(Text.h3Regular)<TitleProps>`
    ${above.laptop} {
        align-self: ${({isImageExists}) => isImageExists && 'center'};
    }
`;

export const Description = styled.p`
    display: flex;
    /* max-width: 973px; */
`;

type ButtonFilledProps = {
    isDisabled: boolean;
};

export const ButtonFilled = styled.a<ButtonFilledProps>`
    margin-top: 32px;
    width: fit-content;
    border: 1px solid black;
    padding: 8px 22px;
    color: white;
    background-color: #17181a;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    font-style: normal;
    font-family: 'ClashGrotesk', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    &:visited {
        color: white;
    }
    transition: 0.3s ease-in-out;
    img {
        transition: 0.3s ease-in-out;
    }
    &:hover {
        background-color: white;
        color: black;
        img {
            filter: invert(1);
        }
    }
    ${({isDisabled}) =>
        isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.3;
        `}
`;

export const Content = styled.div<{isListExist: boolean}>`
    display: flex;
    flex-direction: column;
    gap: ${({isListExist}) => (isListExist ? '32px' : '0px')};
    max-width: 496px;
    margin-right: 107px;
    span {
        ul {
            list-style-type: disc;
            margin-left: -16px;
        }
        span {
            margin: auto;
        }
    }
    ${below.desktop} {
        margin-right: 0;
    }
`;

export const ImageContainer = styled.div`
    width: 100%;

    img {
        width: 100%;
    }
`;

export const FlexItem = styled.div`
    flex: 1;
    max-width: 477px;
    ${below.laptop} {
        display: none;
    }
`;
