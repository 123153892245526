import {useScopeTranslation} from '@everdome_io/next-js-helper';
import {useCallback, useMemo, useState} from 'react';
import {UseFormReset, UseFormSetError} from 'react-hook-form/dist/types/form';

export type ValuesTypes = {
    name: string;
    company: string;
    telegram: string;
    discord: string;
    phone: string;
    email: string;
    // linkedIn: string;
    // twitter: string;
    message: string;
};
export const defaultValues: ValuesTypes = {
    name: '',
    email: '',
    company: '',
    discord: '',
    telegram: '',
    phone: '',
    // linkedIn: '',
    // twitter: '',
    message: '',
};

export default function useFormField(
    reset: UseFormReset<ValuesTypes>,
    setError: UseFormSetError<ValuesTypes>,
) {
    const [status, setStatus] = useState('ready');

    const t = useScopeTranslation('contact-form');

    const onSubmit = useCallback((data: ValuesTypes) => {
        setStatus('loading');

        fetch(`/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                if (res.ok) {
                    setStatus('success');
                    reset();
                } else {
                    res.json().then((errors) => {
                        setStatus('error');
                        Object.entries<string>(errors).forEach(([name, message]) =>
                            setError(name as keyof ValuesTypes, {message}),
                        );
                    });
                }
            })
            .catch(() => {
                setStatus('error');
            })
            .finally(() => setTimeout(() => setStatus('ready'), 5000));
    }, []);

    const fields = useMemo(
        () => [
            {
                name: 'name',
                required: true,
                registerOptions: {
                    pattern: {
                        value: /.{3,100}/i,
                        message: t('errors.invalidName'),
                    },
                },
            },
            {
                name: 'company',
            },
            {
                name: 'email',
                required: true,
                registerOptions: {
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t('errors.invalidEmail'),
                    },
                },
            },
            {
                name: 'telegram',
                span: 'half',
            },
            {
                name: 'discord',
                span: 'half',
            },
            {
                name: 'phone',
            },
            {
                name: 'message',
                required: true,
                props: {
                    multiline: true,
                    rows: 3,
                    minLength: 10,
                    maxLength: 2000,
                },
            },
        ],
        [t],
    );

    return {
        status,
        onSubmit,
        fields,
    };
}
