import useFetch from '~api/useFetch';
import {ApiRoutes} from '~consts/routes';

export const useFetchRecentArticles = () => {
    const {apiGet} = useFetch();

    return () =>
        apiGet(ApiRoutes.Articles, {
            baseUrl: window?.location.origin,
        });
};
