import styled, {css} from 'styled-components';

import {below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';
import {RequirementsSectionPadding} from '~types/graphql/RequirementsSection';

type RequirementsSectionContainerProps = {
    background: SectionBackground;
    customPadding: RequirementsSectionPadding | null;
    topLineExist?: boolean;
};

export const RequirementsSectionContainer = styled.div<RequirementsSectionContainerProps>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 442px));
    gap: 48px;
    justify-content: space-around;
    position: relative;

    &::before {
        content: '';
        ${({topLineExist}) =>
            topLineExist &&
            css`
                position: absolute;
                top: 0;
                width: calc(100% - 2 * var(--h-padding));
                left: var(--h-padding);
                height: 1px;
                border-top: 1px dashed rgba(23, 24, 26, 0.5);
            `};
    }
    padding: ${({customPadding}) =>
        customPadding?.top || customPadding?.bottom
            ? `${customPadding.top || 0}px var(--h-padding) ${
                  customPadding.bottom || 120
              }px var(--h-padding)`
            : '0px var(--h-padding) 120px var(--h-padding)'};

    ${below.tablet} {
        padding: ${({customPadding}) =>
            customPadding?.mobileTop || customPadding?.mobileBottom
                ? `${customPadding.mobileTop || 80}px var(--h-padding) ${
                      customPadding.mobileBottom || 80
                  }px var(--h-padding)`
                : '80px var(--h-padding)'};
    }

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
            `;
        }
    }};
`;

export const Requirements = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
`;

export const LeftText = styled.div`
    min-width: 134.95px;
    text-align: right;
    color: #17181a;
    opacity: 0.6;
`;

export const RightColumn = styled.div`
    max-width: 257.25px;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    gap: 48px;
    .h6Regular {
        line-height: 26px !important;
        letter-spacing: 0.04em;
    }
    ${below.phone} {
        gap: 15px;
    }
`;
