import React, {FormHTMLAttributes} from 'react';

export const Form = ({
    children,
    onSubmit,
    ...rest
}: FormHTMLAttributes<HTMLFormElement>) => {
    return (
        <form
            onSubmit={
                onSubmit ||
                ((ev) => {
                    ev.preventDefault();
                    return false;
                })
            }
            {...rest}
        >
            {children}
        </form>
    );
};
