import {imageProps} from '~utils/imagePropsReplaceDomain';
import {VideoWithOverlaySection as VideoWithOverlaySectionProps} from '~types/graphql/VideoWithOverlaySection';

import {Overlay, Video, VideoWithOverlayContainer} from './styles';

const VideoWithOverlaySection = ({
    overlay,
    video,
    poster,
}: VideoWithOverlaySectionProps) => {
    return (
        <VideoWithOverlayContainer>
            <Video
                {...imageProps(video)}
                // @ts-ignore
                poster={poster ? imageProps(poster).src : ''}
                autoPlay
                playsInline
                muted
                loop
                preload="auto"
            />
            {overlay?.data && <Overlay {...imageProps(overlay)} />}
        </VideoWithOverlayContainer>
    );
};

export default VideoWithOverlaySection;
