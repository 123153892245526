import {AnimatePresence} from 'framer-motion';
import {useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import PageLink from '~components/atoms/PageLink';
import VerticalSectionName from '~components/atoms/Text/VerticalSectionName';
import {AboutEverdomeSection as AboutEverdomeSectionProps} from '~types/graphql/AboutEverdomeSection';
import {GlobalComponentsQueryEventCountdown} from '~types/graphql/GlobalComponentsQuery';
import Motion from '~components/atoms/Motion';
import EventCard from '~components/molecules/EventCard';

import {
    AboutEverdomeSectionContainer,
    TabButton,
    TabsRow,
    TabContainer,
    TextContainer,
    AssetContainer,
    Title,
    Text,
} from './styles';

const AboutEverdomeSection = ({
    sectionName,
    showEventCard,
    eventCardTitle,
    tabs,
    theme,
    eventCountdown,
}: AboutEverdomeSectionProps & {
    eventCountdown?: GlobalComponentsQueryEventCountdown | null;
}) => {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [isInitial, setIsInitial] = useState(true);

    return (
        <AboutEverdomeSectionContainer theme={theme} showEventCard={showEventCard}>
            <VerticalSectionName>{sectionName}</VerticalSectionName>
            <TabsRow>
                {tabs.map((tab) => (
                    <TabButton
                        key={tab.name}
                        selected={selectedTab === tab}
                        onClick={() => {
                            setSelectedTab(tab);
                            setIsInitial(false);
                        }}
                    >
                        {tab.name}
                    </TabButton>
                ))}
            </TabsRow>
            <TabContainer>
                <AnimatePresence>
                    <TextContainer
                        key={selectedTab.name}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                            transition: {
                                delay: 0.4,
                            },
                        }}
                        mobile
                        transition={{duration: 0.5}}
                        exit={{opacity: 0, position: 'absolute'}}
                    >
                        <Title animation={isInitial}>{selectedTab.title}</Title>
                        <Text>{selectedTab.text}</Text>
                        {selectedTab.link && <PageLink link={selectedTab.link} />}
                    </TextContainer>
                </AnimatePresence>

                <AnimatePresence>
                    <AssetContainer
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                            transition: {
                                delay: 0.5,
                            },
                        }}
                        exit={{opacity: 0}}
                        mobile
                        transition={{duration: 0.6}}
                        key={selectedTab.name}
                    >
                        {selectedTab.image?.data && (
                            <Motion.img {...imageProps(selectedTab.image)} />
                        )}
                        {selectedTab.h264Video?.data ? (
                            <Motion.video
                                {...imageProps(selectedTab.image)}
                                autoPlay
                                playsInline
                                muted
                                loop
                                preload="auto"
                            />
                        ) : (
                            selectedTab.vp9Video?.data && (
                                <Motion.video
                                    autoPlay
                                    playsInline
                                    muted
                                    loop
                                    preload="auto"
                                >
                                    <source
                                        src={imageProps(selectedTab.hevcVideo)?.src}
                                        type='video/mp4; codecs="hvc1"'
                                    />
                                    <source
                                        src={imageProps(selectedTab.vp9Video)?.src}
                                        type="video/webm"
                                    />
                                </Motion.video>
                            )
                        )}
                    </AssetContainer>
                </AnimatePresence>
            </TabContainer>
            <ErrorBoundary fallback={null}>
                {showEventCard && eventCountdown?.data?.attributes && (
                    <EventCard
                        {...eventCountdown?.data?.attributes}
                        cardTitle={eventCardTitle}
                    />
                )}
            </ErrorBoundary>
        </AboutEverdomeSectionContainer>
    );
};

export default AboutEverdomeSection;
