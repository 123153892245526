import {useCallback} from 'react';
import {resolveUrl} from '@everdome_io/next-js-helper';

import {useJwtToken} from '~components/providers/AuthProvider';
import useFetchRefreshToken from '~api/hooks/useFetchRefreshToken';
import {
    fetchBody,
    fetchHeaders,
    FetchRequestInit,
    resolveResponse,
} from '~utils/fetch';

export default function useFetch() {
    const {token, setToken} = useJwtToken();
    const fetchRefreshToken = useFetchRefreshToken();
    const fetchAction = useCallback(
        (type: string) =>
            <T = any>(
                url: string,
                {
                    headers,
                    method,
                    queryParams,
                    bindParams,
                    data,
                    body,
                    baseUrl,
                    withJwtToken,
                    withContentType = true,
                    ...params
                }: FetchRequestInit = {},
            ) => {
                const apiCall = (jwtToken = token?.accessToken) =>
                    resolveResponse<T>(
                        fetch(resolveUrl(baseUrl, url, queryParams, bindParams), {
                            ...params,
                            method: type,
                            redirect: 'follow',
                            ...fetchBody({data, body}),
                            ...fetchHeaders({
                                withContentType,
                                withJwtToken,
                                jwtToken,
                                headers,
                            }),
                        }),
                    );
                return withJwtToken &&
                    token &&
                    token.accessToken &&
                    new Date(token.accessToken.expiresIn).getTime() < Date.now() &&
                    new Date(token.refreshToken.expiresIn).getTime() > Date.now()
                    ? new Promise<T>((resolve) => {
                          fetchRefreshToken(token.refreshToken.token)
                              .then((newToken) => {
                                  setToken(newToken);
                                  apiCall(newToken.accessToken).then(resolve);
                              })
                              .catch(() => {
                                  setToken();
                                  apiCall();
                              });
                      })
                    : apiCall();
            },
        [fetchRefreshToken, token],
    );

    return {
        apiGet: fetchAction('GET'),
        apiPost: fetchAction('POST'),
        apiPut: fetchAction('PUT'),
        apiPatch: fetchAction('PATCH'),
        apiDelete: fetchAction('DELETE'),
        fakeGet: <T>(url: string, data: T) => {
            // eslint-disable-next-line no-console
            console.log('Fake fetch data:', url);
            return new Promise<T>((resolve) =>
                setTimeout(() => resolve(data), 1000),
            );
        },
    };
}
