import styled from 'styled-components';

import Text from '~atoms/Text';
import {above} from '~config/breakpoints';

export const SectionTitleWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 0 32px;

    ${above.laptop} {
        margin: 0 0 96px;
    }
`;

export const SectionTitleText = styled(Text.h2Bold)`
    max-width: 1090px;
    text-align: center;
    grid-column: 2;
`;
