import styled from 'styled-components';

import {leftDashedLineCss} from '~components/atoms/DashedLine/styles';
import {PlayerContainer} from '~components/atoms/Player/styles';
import Text from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';

export const VideoWithLinksContainer = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 104px 0 80px;

    ${above.laptop} {
        grid-template-columns: 1fr 350px;
        padding: 128px var(--h-padding);
        gap: 16px 50px;

        & > * {
            grid-column: 2;
        }
    }

    ${below.laptop} {
        & > * {
            margin-inline: var(--h-padding);
        }
    }
`;

export const VideoContainer = styled.div`
    margin-inline: 0;
    grid-column: 1 / span 1;
    overflow: hidden;
    position: relative;

    ${above.laptop} {
        max-height: 560px;
        border-radius: 8px;
        grid-row: 1 / span 3;

        ${PlayerContainer} {
            max-height: 100%;
            min-height: 100%;

            video {
                object-fit: cover;
                object-position: center;
                border-radius: 8px;
            }
        }
    }
`;

export const TitleWrapper = styled.div`
    ${below.laptop} {
        order: -1;
    }
`;

export const SectionName = styled(Text.bodySmallRegular).attrs({tag: 'h6'})`
    opacity: 0.6;
    color: var(--dark-grey);
    margin-bottom: 16px;
`;

export const Title = styled(Text.h4Bold)`
    color: var(--dark-grey);

    ${above.laptop} {
        max-width: 250px;
    }
`;

export const Description = styled(Text.bodyRegular)`
    ${above.laptop} {
        margin: 16px 0;
        min-height: 40px;
    }
`;

export const LinksContainer = styled.div`
    ${leftDashedLineCss}

    ${above.laptop} {
        margin-left: 54px;
    }

    padding: 3px 0 3px 24px;

    display: grid;
    gap: 24px;
`;

export const LinksTitle = styled(Text.bodySmallBold).attrs({tag: 'h6'})``;

export const LinkWithIcon = styled(Text.bodyRegular).attrs({tag: 'span'})`
    display: block;
    margin-left: 28px;
    position: relative;

    svg {
        transition: color 250ms ease-in-out;
        position: absolute;
        right: calc(100% + 8px);
        top: -1px;
    }

    :hover {
        text-decoration: underline;

        svg {
            color: #ff0000;
        }
    }
`;
