import {useInViewAnimation} from '~components/atoms/Animations';
import {StatsSection as StatsSectionProps} from '~types/graphql/StatsSection';

import {Label, StatsContainer, StatWrapper, Value} from './styles';

const StatsSection = ({backgroundTheme, stats}: StatsSectionProps) => {
    const {ref, animationClassName, baseDelay, staggerDelay} = useInViewAnimation();

    return (
        <StatsContainer theme={backgroundTheme} ref={ref}>
            {stats.map(({value, name}, i) => (
                <StatWrapper
                    key={name}
                    className={animationClassName}
                    style={{transitionDelay: `${i * staggerDelay + baseDelay}s`}}
                >
                    <Value>{value}</Value>
                    <Label>{name}</Label>
                </StatWrapper>
            ))}
        </StatsContainer>
    );
};

export default StatsSection;
