import {useMemo} from 'react';
import dayjs from 'dayjs';
// import {Routes} from '~consts/routes';
import {imageProps} from '@everdome_io/next-js-helper';

import {ArticlesDataListQueryArticlesData} from '~types/graphql/ArticlesDataListQuery';

import {Container, Date, Image, Title} from './styles';

const ArticleTile = ({article}: {article: ArticlesDataListQueryArticlesData}) => {
    const date = useMemo(
        () => dayjs(article.attributes.date, 'YYYY-MM-DD').format('MMMM D, YYYY'),
        [article],
    );
    return (
        <Container href={`https://everdome.io/news/${article.attributes.seo.slug}`}>
            {/* Don't replace domain */}
            <Image {...imageProps(article.attributes.seo.poster)} />
            <div>
                <Date>{date}</Date>
                <Title>{article.attributes.seo.title}</Title>
            </div>
        </Container>
    );
};
export default ArticleTile;
