import {useCallback} from 'react';
import {resolveUrl} from '@everdome_io/next-js-helper';

import {fetchBody, fetchHeaders, resolveResponse} from '~utils/fetch';
import {AuthLoginResponse} from '~types/Auth';
import {IdmApiRoutes} from '~consts/routes';

export default function useFetchRefreshToken() {
    return useCallback(
        (refreshToken: string) =>
            resolveResponse<AuthLoginResponse>(
                fetch(
                    resolveUrl(
                        process.env.NEXT_PUBLIC_API_ADDRESS,
                        IdmApiRoutes.RefreshToken,
                    ),
                    {
                        method: 'POST',
                        redirect: 'follow',
                        ...fetchBody({data: {refreshToken}}),
                        ...fetchHeaders({withContentType: true}),
                    },
                ),
            ),
        [],
    );
}
