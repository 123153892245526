import styled, {css} from 'styled-components';

import Motion from '~components/atoms/Motion';
import Text from '~components/atoms/Text';
import {above} from '~config/breakpoints';

export const StatsContainer = styled(Motion.section)<{
    theme: 'white' | 'dark' | 'grey';
}>`
    padding: 80px var(--h-padding);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    justify-content: space-between;
    gap: 32px 40px;

    ${({theme = 'grey'}) => {
        switch (theme) {
            case 'grey':
                return css`
                    background: var(--grey);
                    color: var(--dark-grey);
                `;
            case 'white':
                return css`
                    background: var(--white);
                    color: var(--dark-grey);
                `;
            case 'dark':
                return css`
                    --white: var(--dark-grey);
                    --black: #ffffff;
                    background: var(--white);
                    color: var(--black);
                `;
        }
    }}

    ${above.desktop} {
        padding: 100px var(--h-padding) 104px;
    }
`;

export const StatWrapper = styled.div`
    display: grid;
    justify-content: center;
    grid-auto-rows: min-content;
    gap: 8px;

    ${above.laptop} {
        gap: 24px;
    }
`;

export const Value = styled(Text.h3Bold).attrs({tag: 'span'})`
    text-align: center;
    white-space: nowrap;
`;

export const Label = styled(Text.h7Regular).attrs({tag: 'span'})`
    text-align: center;
`;
