import {motion, MotionProps} from 'framer-motion';
import {FC, ForwardedRef, forwardRef, RefAttributes} from 'react';
import merge from 'lodash.merge';

import RWDHelper from '../RWDHelper';

type Keys = keyof JSX.IntrinsicElements;

type ComponentProps<T extends Keys> = JSX.IntrinsicElements[T];

type CustomProps<T extends Keys> = MotionProps &
    ComponentProps<T> & {mobile?: true | (MotionProps & {deepMerge?: boolean})};

function createComponent<T extends Keys>(
    tagname: T,
    {mobile, ...props}: CustomProps<T>,
    ref: ForwardedRef<JSX.IntrinsicElements[T]>,
) {
    // @ts-ignore
    const MotionComponent = motion[tagname];

    return (
        <RWDHelper
            desktop={<MotionComponent {...props} key="desktop" ref={ref} />}
            mobile={
                <MotionComponent
                    {...props}
                    {...(mobile === true
                        ? {}
                        : mobile
                        ? mobile.deepMerge
                            ? merge({}, props, mobile)
                            : mobile
                        : {
                              initial: false,
                              animate: false,
                              exit: undefined,
                              variants: undefined,
                              transition: undefined,
                          })}
                    key="mobile"
                    ref={ref}
                />
            }
            ssr={true}
        />
    );
}

const componentCache = new Map<string, any>();

const handler: ProxyHandler<Record<Keys, unknown>> = {
    get(_, key: string) {
        let component = componentCache.get(key);
        if (component === undefined) {
            // eslint-disable-next-line react/display-name
            component = forwardRef<JSX.IntrinsicElements[Keys], CustomProps<Keys>>(
                (props, ref) => createComponent(key as Keys, props, ref),
            );

            componentCache.set(key, component);
        }

        return component;
    },
};

const Motion = new Proxy<{
    [K in Keys]: FC<CustomProps<K> & RefAttributes<K>>;
    // @ts-ignore
}>(motion, handler);

export default Motion;
