import styled from 'styled-components';

import Text from '~components/atoms/Text';

export const Container = styled.a`
    display: block;
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 24px;
`;

export const Title = styled(Text.h4Bold)`
    margin-bottom: 16px;
    max-width: 500px;
`;

export const Date = styled(Text.bodySmallRegular).attrs({tag: 'span'})`
    opacity: 0.6;
    margin-bottom: 16px;
    display: inline-block;
`;

export const Description = styled(Text.bodyRegular)`
    margin: 0;
    opacity: 0.6;
    max-width: 500px;
`;
