import styled from 'styled-components';

import {below} from '~config/breakpoints';

import Text from '..';

export const SectionName = styled(Text.bodyRegular)`
    opacity: 0.2;
    writing-mode: vertical-rl;
    transform: scale(-1);
    position: absolute;
    top: 24px;
    left: 24px;
    text-transform: uppercase;

    ${below.laptop} {
        display: none;
    }
`;
