import React from 'react';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import {FaqSection as FaqSectionProps} from '~types/graphql/FaqSection';
import PageLink from '~atoms/PageLink';
import {ButtonIcon} from '~organisms/Layout/Footer/styles';
import QuestionRow from '~atoms/QuestionRow';

import {
    Column,
    Description,
    FAQContent,
    FAQSectionContainer,
    HelpCenter,
    HelpCenterText,
    LinksContainer,
    Row,
    SectionTitle,
    Socials,
} from './styles';

const FAQSection = ({
    title,
    description,
    questions,
    socials,
    links,
    helpCenterLink,
    helpCenterText,
    customPadding,
}: FaqSectionProps) => {
    return (
        <FAQSectionContainer customPadding={customPadding}>
            <Row>
                <Column>
                    <SectionTitle>{title}</SectionTitle>
                    {description && <Description>{description}</Description>}
                    <Socials>
                        {socials?.map((link, index) => (
                            <PageLink link={link} key={index}>
                                {link?.icon.data && (
                                    <ButtonIcon {...imageProps(link.icon.data)} />
                                )}
                                {link?.text}
                            </PageLink>
                        ))}
                    </Socials>
                </Column>
                <FAQContent>
                    {questions?.map((item, index) => (
                        <QuestionRow
                            question={item?.question || ''}
                            key={`${item?.question}_${index}`}
                            answer={item?.answer || ''}
                        />
                    ))}
                    <HelpCenter>
                        <HelpCenterText>{helpCenterText}</HelpCenterText>
                        <PageLink link={helpCenterLink} />
                    </HelpCenter>
                </FAQContent>
            </Row>
            {links?.length ? (
                <LinksContainer>
                    {links?.map((link, index) => (
                        <PageLink link={link} key={index}>
                            {link?.icon.data && (
                                <ButtonIcon {...imageProps(link.icon.data)} />
                            )}
                            {link?.text}
                        </PageLink>
                    ))}
                </LinksContainer>
            ) : null}
        </FAQSectionContainer>
    );
};

export default FAQSection;
