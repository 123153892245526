import styled from 'styled-components';

export const StaticContainer = styled.div`
    padding: 0 var(--h-padding);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
        width: 100%;
        &:nth-child(1) {
            z-index: 2;
        }

        &:nth-child(2) {
            position: absolute;
            width: calc(100% - 2 * var(--h-padding) - 52px);
            top: calc(100% - 18%);
        }
    }
`;
