import styled from 'styled-components';

import RichText from '~components/atoms/RichText';
import {above, below} from '~config/breakpoints';

import {LogosSectionContainer} from '../LogosSection/styles';

export const ContactFormSectionContainer = styled.section`
    padding: 80px var(--h-padding) 100px;
    display: grid;
    gap: 40px;
    position: relative;
    z-index: 1;

    & + ${LogosSectionContainer} {
        padding-top: 0px;
    }
`;

export const Title = styled(RichText)`
    max-width: 496px;
`;

export const ContactFormContainer = styled.div`
    ${above.laptop} {
        position: absolute;
        right: var(--h-padding);
        top: 0;
        width: 41%;
    }

    ${below.laptop} {
        width: 100%;
    }
`;
