import styled, {css} from 'styled-components';

export const AnimatedImageWrapper = styled.div<{rounded: boolean}>`
    ${({rounded}) =>
        rounded &&
        css`
            overflow: hidden;
            border-radius: 8px;
        `}
`;
