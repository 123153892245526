import {ReactNode} from 'react';

import {SectionTitleText, SectionTitleWrapper} from './styles';

type SectionTitleProps = {
    before?: ReactNode;
    children?: string | null;
    after?: ReactNode;
};

const SectionTitle = ({before, children, after}: SectionTitleProps) => {
    return (
        <SectionTitleWrapper>
            {before || <span />}
            <SectionTitleText>{children}</SectionTitleText>
            {after}
        </SectionTitleWrapper>
    );
};

export default SectionTitle;
