import styled, {css, FlattenSimpleInterpolation} from 'styled-components';

import Text, {fonts} from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';

export const Content = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 24px;
    height: fit-content;

    ${below.phone} {
        flex-direction: column;
    }
`;

export const ImagesContainer = styled.div`
    flex: 1;

    ${above.phone} {
        max-width: 600px;
        position: relative;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

    ${above.phone} {
        position: absolute;
    }
`;

export const Description = styled(Text.bodyRegular)<{color: string}>`
    color: ${({color}) => (color ? color : 'rgba(0, 0, 0, 0.5)')};
    .h7Regular {
        font-weight: 300 !important;
    }

    .h7Regular,
    .h7Bold,
    .h7Fancy {
        line-height: 160% !important;
    }

    ${above.tablet} {
        max-width: 600px;
    }
`;

export const Name = styled(Text.bodySmallRegular)`
    color: rgba(0, 0, 0, 0.6);
`;

type TitleProps = {
    isImageExists?: boolean;
    headingCss: FlattenSimpleInterpolation;
};

export const Title = styled.div<TitleProps>`
    text-transform: uppercase;
    ${({headingCss}) =>
        headingCss &&
        css`
            ${headingCss}
        `}
    ${below.phone} {
        .h1Regular {
            font-size: 56px !important;
        }
        .h1Bold {
            font-size: 56px !important;
        }
        .h1Fancy {
            font-size: 46px !important;
        }
    }
    ${({isImageExists}) =>
        isImageExists &&
        css`
            ${fonts.h2Regular}
        `}
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 24px;
`;

type ButtonFilledProps = {
    isDisabled: boolean;
};

export const ButtonFilled = styled.a<ButtonFilledProps>`
    border: 1px solid black;
    padding: 8px 22px;
    color: white;
    background-color: #17181a;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    font-style: normal;
    font-family: 'ClashGrotesk', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    &:visited {
        color: white;
    }
    transition: 0.3s ease-in-out;
    img {
        transition: 0.3s ease-in-out;
    }
    &:hover {
        background-color: white;
        color: black;
        img {
            filter: invert(1);
        }
    }
    ${({isDisabled}) =>
        isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.3;
        `}
`;

export const TextContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    ${below.phone} {
        gap: 24px;
    }
`;

type HeroSectionContainerProps = {
    background: SectionBackground;
};

export const HeroSectionContainer = styled.section<HeroSectionContainerProps>`
    position: relative;
    padding: 16px var(--h-padding) 64px var(--h-padding);
    display: flex;
    flex-direction: column;
    align-items: center;

    ${below.phone} {
        padding: 40px var(--h-padding);
    }

    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;

                ${Name} {
                    color: rgba(255, 255, 255, 0.6);
                }

                ${Description} {
                    color: rgba(255, 255, 255, 0.6);
                }
            `;
        }
    }}
`;
