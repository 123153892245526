import React from 'react';
import {Canvas} from '@react-three/fiber';

import Mars from './Mars';
import {Wrapper} from './styles';

export default function MarsCanvas() {
    return (
        <Wrapper>
            <Canvas
                orthographic
                flat
                camera={{
                    zoom: 400,
                    far: 100000,
                    near: 1,
                }}
            >
                <pointLight position={[-10, 0, 20]} intensity={2.4} />
                <pointLight position={[10, 0, 20]} intensity={0.5} />
                <Mars />
            </Canvas>
        </Wrapper>
    );
}
