import {imageProps} from '@everdome_io/next-js-helper';

import RichText from '~components/atoms/RichText';
import {TextSectionCustomIconAndTextList} from '~types/graphql/TextSection';

import {ListContainer, ListElement} from './styles';

type IconAndTextListProps = {
    list: (TextSectionCustomIconAndTextList | null)[] | null;
};

const IconAndTextList = ({list}: IconAndTextListProps) => {
    return (
        <ListContainer>
            {list?.map((item) => (
                <ListElement>
                    <img {...imageProps(item?.icon)} />
                    <RichText>{item?.text}</RichText>
                </ListElement>
            ))}
        </ListContainer>
    );
};

export default IconAndTextList;
