import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {StyledLink} from '~components/atoms/PageLink/styles';
import RichText from '~components/atoms/RichText';
import Text, {fonts} from '~components/atoms/Text';
import {underlineOnHoverCss} from '~components/atoms/UnderlineOnHover/styles';
import {above, below} from '~config/breakpoints';

import {StatsContainer} from '../StatsSection/styles';

export const ForPlayersContainer = styled.section`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: var(--grey);
    padding: 80px var(--h-padding);
    gap: 48px;

    ${above.laptop} {
        padding: 104px var(--h-padding);
        gap: 104px 10vw;
    }

    & + ${StatsContainer} {
        padding-top: 0;
    }
`;

export const Title = styled(RichText).attrs({tag: 'h2'})`
    color: var(--dark-gray);
    width: 100%;
    z-index: 1;
`;

export const ItemsContainer = styled(Motion.div)`
    display: grid;
    gap: 64px;
    z-index: 1;
    grid-auto-rows: min-content;
    min-height: 500px;

    ${above.phone} {
        min-height: 5600px;
    }

    ${above.laptop} {
        gap: 72px;
        flex-grow: 2;
        max-width: 650px;
        flex-basis: 300px;
        min-height: 490px;
    }
`;

export const ItemContainer = styled(Motion.div)`
    color: var(--dark-grey);
    padding: 0 0 0 24px;
    border-left: 2px solid currentColor;
    display: grid;
    gap: 24px;
    grid-auto-rows: min-content;
`;

export const ItemTitle = styled(Text.h4Regular).attrs({tag: 'h6'})`
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
`;

export const ItemText = styled(Text.bodyRegular)`
    & > span {
        opacity: 0.6;
        display: block;
        margin-bottom: 24px;
    }

    ${StyledLink} {
        display: block;
        text-transform: uppercase;
        ${fonts.h7Bold}
    }
`;

export const LinksContainer = styled(Motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 48px;
    margin: 340px 0 4px 0;
    padding: 0 40px 12px 0;
    position: relative;

    ${above.laptop} {
        flex-basis: 400px;
        flex-grow: 1;
        gap: 40px;
        margin: 200px 0 4px 0;
    }

    ${below.laptop} {
        width: 100%;
    }

    a {
        z-index: 2;
        ${fonts.h5Regular}
        mix-blend-mode: difference;
        position: relative;
        max-width: max-content;
        color: var(--white);

        span {
            display: inline-block;
            transform: translateY(-5px);
            margin-right: 3px;
            font-size: 25px;
        }

        ${underlineOnHoverCss}
    }
`;

export const AssetsContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: -30px;
    width: calc(100% + 100px);
    height: 600px;
    z-index: 0;

    ${above.laptop} {
        right: 0;
        left: unset;
        width: 500px;
    }
`;

export const ImageOrVideoWrapper = styled(Motion.div)`
    position: absolute;
    overflow: hidden;
    border-radius: 8px;
    display: flex;

    video {
        border-radius: 8px;
    }

    &:nth-child(1) {
        right: 0;
        bottom: 0;
        width: 288px;
    }

    &:nth-child(2) {
        left: 5px;
        top: 130px;
        width: 250px;

        ${above.laptop} {
            top: 200px;
            width: 223px;
        }
    }

    &:nth-child(3) {
        right: 0;
        top: 0;
        width: 183px;
    }
`;
