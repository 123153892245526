import {useRef} from 'react';
import Slider from 'react-slick';

import {TestimonialsSectionTestimonials} from '~types/graphql/TestimonialsSection';
import ArrowRight from '~assets/icons/arrow-right-big.svg';
import ArrowLeft from '~assets/icons/arrow-left-big.svg';

import Testimonial from '../Testimonial';

import {ControlButtons, TestimonialsCarouselContainer} from './styles';

type TestimonialsCarouselProps = {
    testimonials: TestimonialsSectionTestimonials[];
};

const TestimonialsCarousel = ({testimonials}: TestimonialsCarouselProps) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const sliderRef = useRef<Slider>(null);

    return (
        <TestimonialsCarouselContainer>
            <Slider ref={sliderRef} {...settings}>
                {testimonials.map((item, index) => (
                    <Testimonial key={`${item.author}_${index}`} {...item} />
                ))}
            </Slider>
            <ControlButtons>
                <button onClick={() => sliderRef.current?.slickPrev()}>
                    <ArrowLeft />
                </button>
                <button onClick={() => sliderRef.current?.slickNext()}>
                    <ArrowRight />
                </button>
            </ControlButtons>
        </TestimonialsCarouselContainer>
    );
};

export default TestimonialsCarousel;
