import {useRef} from 'react';

import {imageProps} from '~utils/imagePropsReplaceDomain';
import Text from '~components/atoms/Text';
import {If} from '~components/atoms/If';
import BulletList from '~components/molecules/BulletList';
import {ImageAndTextSection as ImageAndTextSectionProps} from '~types/graphql/ImageAndTextSection';
import RichText from '~components/atoms/RichText';
import Link from '~components/atoms/Link';
import useRefDimensions from '~hooks/useRefDimensions';
import AdditionalBulletList from '~components/molecules/BulletList/AdditionalBulletList';

import {
    Description,
    ImageAndTextContainer,
    ImageContainer,
    LinkIcon,
    TextContainer,
    Title,
} from './styles';
import ImageOrVideo from './ImageOrVideo';

const ImageAndTextSection = ({
    sectionName,
    children,
    list,
    imagesAndTextBackground,
    customPadding,
    additionalList,
}: ImageAndTextSectionProps) => {
    const container = useRef<HTMLDivElement>(null);
    const {height} = useRefDimensions(container);

    return (
        <ImageAndTextContainer
            background={imagesAndTextBackground}
            order={children.order}
            customPadding={customPadding}
        >
            <ImageContainer height={height}>
                <ImageOrVideo media={children.image} />
            </ImageContainer>
            <TextContainer ref={container}>
                <If condition={sectionName}>
                    <Title>{sectionName}</Title>
                </If>
                <Text.h3Regular>
                    <RichText>{children.title}</RichText>
                </Text.h3Regular>
                <Description>
                    <RichText>{children.description}</RichText>
                </Description>
                <If condition={list}>
                    <BulletList list={list} />
                </If>
                <If condition={additionalList}>
                    <AdditionalBulletList list={additionalList} />
                </If>
                <If condition={children.link}>
                    <Text.h7Bold>
                        <Link link={children.link}>
                            {children.link?.text}
                            <LinkIcon {...imageProps(children.link?.icon)} />
                        </Link>
                    </Text.h7Bold>
                </If>
            </TextContainer>
        </ImageAndTextContainer>
    );
};

export default ImageAndTextSection;
