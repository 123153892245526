import {imageProps} from '~utils/imagePropsReplaceDomain';
import {If} from '~components/atoms/If';
import Text from '~components/atoms/Text';
import {FeaturesSection as FeatureSectionProps} from '~types/graphql/FeaturesSection';
import RichText from '~components/atoms/RichText';

import {
    Description,
    FeatureBlock,
    FeaturesContainer,
    FeaturesSectionContainer,
    Icon,
    SectionName,
} from './styles';

const FeaturesSection = ({
    sectionName,
    features,
    featuresBackground,
    customPadding,
    sectionNameAlign,
}: FeatureSectionProps) => {
    return (
        <FeaturesSectionContainer
            customPadding={customPadding}
            background={featuresBackground}
        >
            <If condition={sectionName}>
                <SectionName align={sectionNameAlign}>
                    <RichText>{sectionName}</RichText>
                </SectionName>
            </If>
            <FeaturesContainer>
                {features?.map((feature) => (
                    <FeatureBlock key={feature?.title}>
                        <If condition={feature?.icon.data}>
                            <Icon {...imageProps(feature?.icon)} />
                        </If>
                        <If condition={feature?.title}>
                            <Text.h6Regular>{feature?.title}</Text.h6Regular>
                        </If>
                        <If condition={feature?.title}>
                            <Description opacity={feature?.descriptionOpacity}>
                                <RichText>{feature?.description}</RichText>
                            </Description>
                        </If>
                    </FeatureBlock>
                ))}
            </FeaturesContainer>
        </FeaturesSectionContainer>
    );
};

export default FeaturesSection;
