import styled, {css} from 'styled-components';

import AnimatedImage from '~components/atoms/AnimatedImage';
import {fontFamilies} from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';
import {SectionBackground} from '~types/background';
import {ImagesAndVideosSection} from '~types/graphql/ImagesAndVideosSection';

type ImagesAndVideosContainerProps = {
    background: SectionBackground;
    isFramed: boolean;
    isHalfBackground: boolean;
};

export const ImagesAndVideosContainer = styled.section<ImagesAndVideosContainerProps>`
    width: 100%;
    position: relative;
    ${above.phone} {
        ${({isFramed}) =>
            isFramed
                ? css`
                      padding: 80px var(--h-padding);
                  `
                : css`
                      padding: 40px var(--h-padding);
                  `}
    }

    ${({background, isHalfBackground}) => {
        if (background === 'White' && !isHalfBackground) {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey' && !isHalfBackground) {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black' && !isHalfBackground) {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
            `;
        }
    }}
`;

type HalfBackgroundProps = {
    background: SectionBackground;
    isBottom?: boolean;
};

export const HalfBackground = styled.div<HalfBackgroundProps>`
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: -1;
    top: ${({isBottom}) => (isBottom ? '50%' : '0%')};
    left: 0;
    ${({background}) => {
        if (background === 'White') {
            return css`
                background-color: #fff;
            `;
        }
        if (background === 'Grey') {
            return css`
                background-color: #f7f7f7;
            `;
        }
        if (background === 'Black') {
            return css`
                background-color: var(--dark-grey);
                color: #fff;
            `;
        }
    }};
`;

type ImageOrVideoContainerProps = {
    height?: number;
    contain: boolean | null;
};

export const Image = styled(AnimatedImage)`
    width: 100%;
`;

export const ImageOrVideoContainer = styled.div<ImageOrVideoContainerProps>`
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    ${Image} {
        /* height: 100%; */
        object-fit: ${({contain}) => contain && 'contain'};
    }
    video {
        object-fit: ${({contain}) => (contain ? 'contain' : 'cover')};
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

type ContentProps = {
    framed?: ImagesAndVideosSection['framed'];
    numberOfItems: number;
    description: boolean;
    disablePadding: boolean;
    descriptionHeight: number | undefined;
};

export const Content = styled.div<ContentProps>`
    width: 100%;
    margin: 0 auto;
    display: grid;

    img {
        object-fit: cover;
    }

    ${ImageOrVideoContainer}, img {
        min-height: 400px;
    }
    ${TextWrapper} {
        &:nth-child(1) {
            h6 {
                max-width: 210px;
            }
        }
    }

    ${below.phone} {
        ${({numberOfItems, framed}) =>
            framed &&
            css<{numberOfItems: typeof numberOfItems}>`
                padding: 80px var(--h-padding);
                display: flex;
                flex-direction: column;
                gap: 80px;
                ${({numberOfItems}) =>
                    numberOfItems === 1 &&
                    css`
                        ${ImageOrVideoContainer} {
                            height: 360px;
                        }
                    `}
                ${({numberOfItems}) =>
                    numberOfItems === 2 &&
                    css`
                        ${TextWrapper} {
                            flex-direction: column-reverse;

                            &:nth-child(1) {
                                max-width: 255px;
                                align-self: center;

                                ${ImageOrVideoContainer} {
                                    height: 316px;
                                }
                            }

                            &:nth-child(2) {
                                ${ImageOrVideoContainer} {
                                    height: 350px;
                                }
                            }

                            ${MediaDescription} {
                                text-transform: none;
                            }

                            ${SectionDescriptionMobile} {
                                display: none;
                            }
                        }
                    `}
              ${({numberOfItems}) =>
                    numberOfItems === 3 &&
                    css`
                        ${TextWrapper} {
                            flex-direction: column-reverse;

                            &:nth-child(1) {
                                ${ImageOrVideoContainer} {
                                    height: 434px;
                                }
                            }

                            &:nth-child(2) {
                                ${ImageOrVideoContainer} {
                                    height: 250px;
                                }
                            }

                            &:nth-child(3) {
                                ${ImageOrVideoContainer} {
                                    height: 350px;
                                }
                            }

                            ${MediaDescription} {
                                text-transform: none;
                            }

                            ${SectionDescriptionMobile} {
                                display: block;
                                text-transform: uppercase;
                            }
                        }
                    `}
            `}
    }

    ${above.phone} {
        ${({numberOfItems, framed}) =>
            framed
                ? numberOfItems > 1 &&
                  css`
          grid-template-rows: 614px;
          grid-template-columns: 392px 1fr;

          ${below.tablet} {
            grid-template-columns: 328px 1fr;
          }

          ${TextWrapper} {
            &:nth-child(1) {
              padding-top: 80px;
              flex-direction: column-reverse;
            }

            &:nth-child(2) {
              position: relative;
              grid-column: 2/3;
              flex-direction: row;
              width: 100%;
              max-width: 600px;
              flex-direction: row;
              margin-right: 0;
              margin-left: auto;

              ${MediaDescription} {
                z-index: 2;
                writing-mode: vertical-rl;
                text-orientation: mixed;
                width: fit-content;
                height: fit-content;
                display: flex;
                margin 0 0 auto 0;
                transform-origin: center center;
                transform: rotate(180deg);
              }

              ${ImageOrVideoContainer} {
                flex: 1;
              }
            }

            ${ImageOrVideoContainer} {
              height: 100%;
            }
          }`
                : css``}

        ${({
            numberOfItems,
            framed,
            description,
            disablePadding,
            descriptionHeight,
        }) =>
            framed
                ? numberOfItems >= 2 &&
                  css<{
                      description: typeof description;
                      disablePadding: typeof disablePadding;
                      numberOfItems: typeof numberOfItems;
                      descriptionHeight: typeof descriptionHeight;
                  }>`
                      grid-template-rows: ${({numberOfItems}) =>
                          numberOfItems > 2 ? '614px 436px' : '614px'};
                      row-gap: ${({description, descriptionHeight}) =>
                          description && descriptionHeight
                              ? `${descriptionHeight + 160}px`
                              : '160px'};

                      ${TextWrapper} {
                          &:nth-child(2) {
                              padding-bottom: ${({disablePadding}) =>
                                  disablePadding ? '41px' : '178px'};
                          }
                          ${({numberOfItems}) =>
                              numberOfItems > 2 &&
                              css`
                                  &:nth-child(3) {
                                      position: relative;
                                      grid-column: 1/4;
                                  }
                              `}
                      }
                  `
                : css``}
    }
`;

export const Controls = styled.div`
    position: absolute;
    inset: 24px 24px 24px 24px;
`;

export const Play = styled.button`
    position: absolute;
    inset: 0 0 0 0;
`;

export const Volume = styled.button`
    position: absolute;
    bottom: 0;
    z-index: 2;
`;

export const Wrapper = styled.div`
    position: absolute;
    inset: 24px 24px 24px 24px;
    z-index: 2;
    pointer-events: none;
`;

export const FlexItem = styled.div`
    flex: 1;
    border-bottom: 1px dashed white;
    transform: translateY(-50%) translateY(1px);
    margin: 0 24px;
`;

type RightTextProps = {
    width: number;
};

export const RightText = styled.div<RightTextProps>`
    width: ${({width}) => `${width}px`};
    position: absolute;
    right: 0;
    top: -24px;
    transform-origin: right bottom;
    transform: rotate(-90deg);
    color: white;
    display: flex;
    align-items: center;
    ${fontFamilies.openSauceOne}

    ${below.laptop} {
        svg {
            display: none;
        }

        ${FlexItem} {
            display: none;
        }
    }
`;

export const MediaDescription = styled.div`
    // @FONTS: check des
    ${fontFamilies.clashGrotesk}
    text-transform: uppercase !important;
`;

export const SectionDescriptionDesktop = styled(MediaDescription)`
    position: absolute;
    z-index: 2;
    max-width: 600px;
    right: 0;
    top: -80px;
    transform: translateY(-100%);
    width: fit-content;
    height: fit-content;

    ${above.laptop} {
        top: -160px;
    }

    ${below.phone} {
        display: none !important;
    }
`;

export const SectionDescriptionMobile = styled(MediaDescription)`
    margin-top: 24px;

    ${above.phone} {
        display: none !important;
    }
`;
