import {ComponentProps} from 'react';

import {imageProps as getImageProps} from '~utils/imagePropsReplaceDomain';
import {ImageOrVideoQuery} from '~types/graphql/ImageOrVideoQuery';
import Player, {PlayerProps} from '~components/atoms/Player';

import AnimatedImage from '../AnimatedImage';

type ImageOrVideoProps = {
    imageOrVideo: ImageOrVideoQuery | null | undefined;
    playerProps?: Partial<PlayerProps>;
    imageProps?: Partial<ComponentProps<typeof AnimatedImage>>;
};

const ImageOrVideo = ({
    imageOrVideo,
    playerProps,
    imageProps,
}: ImageOrVideoProps) => {
    if (!imageOrVideo) {
        return null;
    }

    const isVideo =
        imageOrVideo.videoUrl ||
        imageOrVideo.media?.data?.attributes.mime.includes('video');

    if (isVideo) {
        return (
            // @ts-ignore
            <Player
                {...(imageOrVideo.videoUrl
                    ? {videoUrl: imageOrVideo.videoUrl}
                    : {video: imageOrVideo.media!})}
                {...playerProps}
            />
        );
    } else if (imageOrVideo.media) {
        return (
            <AnimatedImage
                {...getImageProps(imageOrVideo?.media)}
                width="100%"
                {...imageProps}
            />
        );
    }

    return null;
};

export default ImageOrVideo;
