import styled from 'styled-components';

import {leftDashedLineCss} from '~components/atoms/DashedLine/styles';
import Text, {fonts} from '~components/atoms/Text';
import {underlineOnHoverCss} from '~components/atoms/UnderlineOnHover/styles';
import {above, below} from '~config/breakpoints';
import {FaqSectionCustomPadding} from '~types/graphql/FaqSection';

export const FAQSectionContainer = styled.section<{
    customPadding?: FaqSectionCustomPadding | null;
}>`
    padding: ${({customPadding}) =>
        customPadding?.top || customPadding?.bottom
            ? `${customPadding.top || 104}px var(--h-padding) ${
                  customPadding.bottom || 0
              }px var(--h-padding)`
            : '104px var(--h-padding) 0px var(--h-padding)'};
    ${below.tablet} {
        padding: ${({customPadding}) =>
            customPadding?.mobileTop || customPadding?.mobileBottom
                ? `${customPadding.mobileTop || 104}px var(--h-padding) ${
                      customPadding.mobileBottom || 0
                  }px var(--h-padding)`
                : '104px var(--h-padding) 0px var(--h-padding)'};
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: center;

    ${below.tablet} {
        flex-direction: column;
    }
`;

export const SectionTitle = styled(Text.h3Fancy)`
    margin-bottom: 24px;

    ${below.tablet} {
        margin-bottom: 16px;
    }
`;

export const FAQContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: -80px;
    width: calc(45% + 80px);
    margin-top: 40px;

    ${above.desktop} {
        margin-left: -100px;
        width: calc(45% + 100px);
    }

    ${below.tablet} {
        width: 100%;
        margin-left: 0;
        margin-top: 48px;
    }
`;

export const Icon = styled.div`
    min-width: 18px;
`;

export const Description = styled(Text.bodyRegular)`
    margin-bottom: 24px;
    color: var(--dark-grey);
    opacity: 0.6;

    ${above.tablet} {
        margin-bottom: 80px;
        max-width: 330px;
    }
`;

export const Socials = styled.div`
    line-height: 100%;
    display: grid;
    gap: 40px;

    ${above.tablet} {
        max-width: 330px;
    }

    div {
        width: 100%;
    }

    img {
        width: 16px;
        margin-right: 6px;
    }

    a {
        align-items: center;
        width: 100%;
        ${fonts.h7Regular};
    }
`;

export const Column = styled.div`
    background: #f7f7f7;
    padding: 64px 200px 64px 64px;
    width: 55%;
    border-radius: 8px;

    ${below.desktop} {
        padding: 32px 200px 32px 24px;
    }

    ${below.tablet} {
        width: 100%;
        padding: 32px 24px;
    }
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 32px 64px;
    gap: 24px;
    background: var(--dark-grey);
    border-radius: 8px;
    margin-top: 5px;
    z-index: 1;
    position: relative;
    color: white;
    justify-content: space-between;

    ${below.tablet} {
        gap: 15px;
        margin-top: 3px;
        padding: 32px 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    & > div {
        --line-color: var(--white);
        padding-block: 14px;
        width: auto;

        ${above.tablet} {
            &:not(:first-child) {
                ${leftDashedLineCss}
                padding-left: 22px;
                border-radius: 0;
            }
        }

        ${below.tablet} {
            &:nth-child(even) {
                ${leftDashedLineCss}
                border-radius: 0;
                padding-left: 15px;
            }
        }
    }

    a {
        ${underlineOnHoverCss}
        ${fonts.h7Regular}
        text-overflow: ellipsis;
        display: block;
        min-width: 0;
    }

    img {
        display: block;
        margin-bottom: 12px;
    }
`;

export const HelpCenter = styled.div`
    margin-top: 9px;
    margin-bottom: 45px;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;

    a {
        ${fonts.bodyBold}
    }
`;

export const HelpCenterText = styled(Text.bodyRegular).attrs({tag: 'span'})`
    display: inline-block;
    color: var(--dark-grey);
    opacity: 0.6;
    margin-right: 18px;
`;
