import styled from 'styled-components';

import Text, {fonts} from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';

export const Container = styled.a`
    display: flex;
    gap: 24px;
    margin-bottom: 48px;
`;

export const Image = styled.img`
    height: 136px;

    width: 50%;
    object-fit: cover;
    border-radius: 8px;
    object-position: center;

    ${below.phone} {
        height: auto;
    }

    ${above.phone} {
        min-width: 250px;
        max-width: 250px;
    }
`;

export const Title = styled(Text.bodyRegular).attrs({tag: 'h3'})`
    ${below.phone} {
        ${fonts.bodySmallRegular}
    }
`;

export const Date = styled(Text.bodySmallRegular).attrs({tag: 'span'})`
    opacity: 0.6;
`;
