import {useMemo} from 'react';

import {useRecentArticles} from '~hooks/useRecentArticles';

const useArticles = () => {
    const featuredArticles = useRecentArticles();
    const latestArticle = useMemo(() => {
        if (featuredArticles && featuredArticles.length > 0) {
            return [...featuredArticles].shift();
        }
        return undefined;
    }, [featuredArticles]);

    const otherArticles = useMemo(() => {
        if (featuredArticles && featuredArticles.length > 0) {
            return [...featuredArticles].slice(1);
        }
        return undefined;
    }, [featuredArticles]);

    return {latestArticle, otherArticles};
};

export default useArticles;
