import RichText from '~components/atoms/RichText';
import {H6} from '~components/download/atoms/Text';
import {RequirementsSection as RequirementsSectionProps} from '~types/graphql/RequirementsSection';

import {LeftText, Requirements, RequirementsSectionContainer, Row} from './styles';

const RequirementsSection = ({
    requirementsBackground,
    pc,
    mac,
    topLine,
    padding,
}: RequirementsSectionProps) => {
    return (
        <RequirementsSectionContainer
            background={requirementsBackground}
            customPadding={padding}
            topLineExist={Boolean(topLine)}
        >
            {mac && (
                <Requirements>
                    {mac?.map((item, index) => (
                        <Row>
                            <LeftText>
                                {index === 0 ? (
                                    <H6
                                        style={{
                                            textTransform: 'uppercase',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        {item?.label}
                                    </H6>
                                ) : (
                                    item?.label
                                )}
                            </LeftText>
                            <RichText>{item?.info}</RichText>
                        </Row>
                    ))}
                </Requirements>
            )}
            {pc && (
                <Requirements>
                    {pc?.map((item, index) => (
                        <Row>
                            <LeftText>
                                {index === 0 ? (
                                    <H6
                                        style={{
                                            textTransform: 'uppercase',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        {item?.label}
                                    </H6>
                                ) : (
                                    item?.label
                                )}
                            </LeftText>
                            <RichText>{item?.info}</RichText>
                        </Row>
                    ))}
                </Requirements>
            )}
        </RequirementsSectionContainer>
    );
};

export default RequirementsSection;
