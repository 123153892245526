import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {below} from '~config/breakpoints';

export const VideoWithOverlayContainer = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;

    ${below.tablet} {
        height: calc(100vh - var(--topbar-height) + 1px);
    }
`;

export const Video = styled(Motion.video)`
    position: absolute;
    min-width: 100%;
    min-height: calc(100% - 1px);
    max-height: calc(100% - 1px);
    object-fit: cover;
    object-position: center;
`;

export const Overlay = styled(Motion.img)`
    width: 100%;
    object-position: center;
    z-index: 1;
`;
