import styled from 'styled-components';

import Link from '~components/atoms/Link';
import Motion from '~components/atoms/Motion';
import Text from '~components/atoms/Text';
import {above, below} from '~config/breakpoints';

export const EventCardContainer = styled(Motion.div)`
    position: absolute;
    z-index: 1;
    background: var(--orange);
    border-radius: 10px;
    --offset: 40px;
    bottom: calc(-1 * var(--offset));
    inset-inline: 16px;
    padding: 24px 24px 32px;
    --white: #ffffff;
    color: var(--white);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 16px;

    ${above.laptop} {
        gap: 0;
        padding: 0 5vw 100px 44px;
        inset-inline: unset;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        right: 0;
        --offset: 32px;
        bottom: calc(-1 * var(--offset));
        height: calc(100% + var(--offset) * 2);
        min-height: min-content;
        width: var(--event-card-height, 292px);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const Title = styled(Text.h5Regular)`
    margin: 24px 0;
    min-width: 100%;

    ${above.laptop} {
        margin: 64px 0 48px;
    }
`;

export const CardTitle = styled(Text.bodySmallRegular).attrs({tag: 'h6'})`
    opacity: 0.6;
    text-transform: uppercase;

    ${below.laptop} {
        padding: 2px 0;
        width: 100%;
        border-bottom: 1px solid currentColor;
    }

    ${above.laptop} {
        writing-mode: vertical-rl;
        transform: scale(-1);
        min-height: 200px;
        padding: 0 12px;
        border-right: 1px solid currentColor;
    }
`;

export const Description = styled(Text.bodySmallRegular)`
    opacity: 0.6;
    min-width: 170px;
    max-width: 300px;
    white-space: pre-line;

    ${above.laptop} {
        margin: 0 0 48px;
    }
    ${below.laptop} {
        margin-bottom: 8px;
        flex-grow: 1;
        flex-shrink: 3;
        flex-basis: 170px;
    }
`;

export const CountdownContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px 8px;
    align-items: center;

    ${above.laptop} {
        gap: 24px 8px;
    }

    ${below.laptop} {
        align-content: center;
    }

    > :nth-child(5),
    > :nth-child(6) {
        display: none;
    }
`;

export const Value = styled(Text.h5Regular).attrs({tag: 'span'})`
    width: 48px;
`;

export const Label = styled(Text.bodyRegular).attrs({tag: 'span'})`
    opacity: 0.6;
    text-transform: uppercase;
`;

export const ButtonLink = styled(Link)`
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 9px 35px;
    margin: 32px 0 0;
    width: 100%;
    text-align: center;
    transition: background-color 600ms ease-in-out;

    :hover {
        background: rgba(255, 255, 255, 0.3);
    }

    ${above.laptop} {
        margin: 60px 0 0;
        width: auto;
    }

    ${below.laptop} {
        align-self: flex-end;
    }
`;
