import PageLink from '~components/atoms/PageLink';
import {CallToActionSection as CallToActionSectionProps} from '~types/graphql/CallToActionSection';
import MarsCanvas from '~molecules/MarsCanvas';
import StarsAnimation from '~molecules/StarsAnimation';
import RichText from '~components/atoms/RichText';

import {
    CTAContainer,
    CTAContent,
    Description,
    Dot,
    MarsContainer,
    Stars,
} from './styles';

const CallToActionSection = ({
    title,
    description,
    link,
}: CallToActionSectionProps) => {
    return (
        <CTAContainer>
            <MarsContainer>
                <MarsCanvas />
            </MarsContainer>
            <Stars>
                <StarsAnimation />
            </Stars>
            <CTAContent>
                <RichText tag="h2">{title}</RichText>
                <Description>{description}</Description>
                <Dot />
                <PageLink link={link}>{link.text}</PageLink>
            </CTAContent>
        </CTAContainer>
    );
};

export default CallToActionSection;
