import styled from 'styled-components';

import {
    bottomDashedLineCss,
    topDashedLineCss,
} from '~components/atoms/DashedLine/styles';
import {fonts} from '~components/atoms/Text';
import {below} from '~config/breakpoints';

export const Wrapper = styled.div`
    color: white;
    background: var(--dark-grey);
    padding: 80px 0 184px;
    --line-color: var(--white);

    ${below.laptop} {
        padding: 40px 0 80px;
    }
`;

export const Container = styled.div`
    padding: 0 var(--h-padding);
    display: grid;
    gap: 128px;
    grid-template-columns: 50% auto;

    ${below.desktop} {
        gap: 40px;
    }

    ${below.laptop} {
        display: flex;
        flex-direction: column;
    }
`;

export const Title = styled.h2`
    margin-bottom: 96px;
    display: inline-flex;
    align-items: center;
    padding: 0 24px;

    ${fonts.h1Regular}

    ${below.laptop} {
        margin-bottom: 40px;
    }

    &:before {
        content: '';
        background: white;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin-top: 25px;
        margin-right: 48px;
    }
`;

export const OtherArticles = styled.div`
    & > div:not(:last-of-type) {
        padding-bottom: 48px;
        ${bottomDashedLineCss}
    }

    ${below.laptop} {
        ${topDashedLineCss}
        padding-top: 48px;
    }
`;

export const Line = styled.div`
    display: inline-block;

    ${bottomDashedLineCss}
    flex-grow: 1;
    height: 1px;
    margin-right: 40px;

    ${below.laptop} {
        margin-right: 12px;
    }
`;

export const SeeMoreContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 40px;

    ${below.laptop} {
        margin-right: 0px;
    }

    a {
        display: flex;
        align-items: center;
        ${fonts.bodyBold}
    }
`;
