import styled, {css} from 'styled-components';

import RichText from '~components/atoms/RichText';
import Text from '~components/atoms/Text';
import {below} from '~config/breakpoints';
import {TitleDescriptionSectionCustomPadding} from '~types/graphql/TitleDescriptionSection';

import {LogosSectionContainer} from '../LogosSection/styles';

export const TitleDescriptionContainer = styled.section<{
    customPadding: TitleDescriptionSectionCustomPadding | null;
}>`
    display: grid;
    gap: 48px;
    justify-items: center;

    .h5Regular {
        font-weight: 300 !important;
    }
    .h6Regular {
        font-weight: 300 !important;
    }

    padding: ${({customPadding}) =>
        customPadding?.top || customPadding?.bottom
            ? `${customPadding.top || 160}px var(--h-padding) ${
                  customPadding.bottom || 160
              }px var(--h-padding)`
            : '160px var(--h-padding) 160px var(--h-padding)'};

    ${below.tablet} {
        padding: ${({customPadding}) =>
            customPadding?.mobileTop || customPadding?.mobileBottom
                ? `${customPadding.mobileTop || 80}px var(--h-padding) ${
                      customPadding.mobileBottom || 80
                  }px var(--h-padding)`
                : '80px var(--h-padding)'};
    }

    & + ${LogosSectionContainer} {
        padding-top: 0;
    }
`;

export const Title = styled(RichText).attrs({tag: 'h1'})`
    color: var(--dark-grey);
    max-width: 929px;
    text-align: center;
`;

type DescriptionProps = {
    descriptionColor?: string;
};

export const Description = styled(Text.bodyRegular)<DescriptionProps>`
    color: ${({descriptionColor}) =>
        descriptionColor ? `${descriptionColor}` : 'rgba(23, 24, 26, 0.5)'};
    max-width: 600px;
    text-align: center;
    ul {
        text-align: left;
        transform: translateX(-20px);
    }
    .h7Regular {
        font-weight: 300 !important;
    }
    .h7Regular,
    .h7Bold,
    .h7Fancy {
        line-height: 160% !important;
    }
`;

type ButtonFilledProps = {
    isDisabled: boolean;
};

export const ButtonFilled = styled.a<ButtonFilledProps>`
    border: 1px solid black;
    padding: 8px 22px;
    color: white;
    background-color: #17181a;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    font-style: normal;
    font-family: 'ClashGrotesk', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 100%;
    &:visited {
        color: white;
    }
    transition: 0.3s ease-in-out;
    img {
        transition: 0.3s ease-in-out;
    }
    &:hover {
        background-color: white;
        color: black;
        img {
            filter: invert(1);
        }
    }
    ${({isDisabled}) =>
        isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.3;
        `}
`;

export const AdditionalDescription = styled.div`
    max-width: 600px;
    text-align: left;
`;
