import {useEffect, useRef, useState} from 'react';

import Text from '~atoms/Text';
import ArrowDown from '~assets/icons/line-arrow-down.svg';

import RichText from '../RichText';

import {Answer, Question, Row} from './styles';

const QuestionRow = ({question, answer}: {question: string; answer: string}) => {
    const [active, setActive] = useState(false);

    const answerRef = useRef<HTMLDivElement>(null!);

    useEffect(() => {
        answerRef.current.style.maxHeight = active
            ? `${answerRef.current.scrollHeight}px`
            : '0px';
    }, [answerRef, active]);

    return (
        <Row
            onClick={(ev) => {
                !['A', 'BUTTON'].includes((ev.target as HTMLElement)?.tagName) &&
                    setActive(!active);
            }}
            active={active}
        >
            <Question>
                <Text.bodyBold tag="h6">{question}</Text.bodyBold>
                <ArrowDown />
            </Question>
            <Answer ref={answerRef}>
                <RichText animation={false} tag="p">
                    {answer}
                </RichText>
            </Answer>
        </Row>
    );
};
export default QuestionRow;
