import {imageProps} from '@everdome_io/next-js-helper';

import {StaticImageSection as StaticImageSectionProps} from '~types/graphql/StaticImageSection';

import {StaticContainer} from './styles';

const StaticImageSection = ({staticImage}: StaticImageSectionProps) => {
    return (
        <StaticContainer>
            <img {...imageProps(staticImage)} />
        </StaticContainer>
    );
};
export default StaticImageSection;
