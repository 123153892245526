import {useRef} from 'react';

import {ImagesAndVideosSection as ImagesAndVideosProps} from '~types/graphql/ImagesAndVideosSection';
import {If} from '~components/atoms/If';
import Text from '~components/atoms/Text';
import RichText from '~components/atoms/RichText';
import useRefDimensions from '~hooks/useRefDimensions';

import ImageOrVideo from './ImageOrVideo';
import {
    Content,
    HalfBackground,
    ImagesAndVideosContainer,
    MediaDescription,
    SectionDescriptionDesktop,
    SectionDescriptionMobile,
    TextWrapper,
} from './styles';
import useSliceMedia from './useSliceMedia';

const ImagesAndVideosSection = ({
    galleryMedias,
    framed,
    description,
    imagesAndVideosBackground,
    disablePadding,
    bottomSecondBackground,
}: ImagesAndVideosProps) => {
    const descriptionRef = useRef<HTMLDivElement>(null);
    const filteredMedias = useSliceMedia(galleryMedias, framed);

    const {height} = useRefDimensions(descriptionRef);

    return (
        <ImagesAndVideosContainer
            background={imagesAndVideosBackground}
            isFramed={framed}
            isHalfBackground={Boolean(bottomSecondBackground)}
        >
            <Content
                framed={framed}
                numberOfItems={filteredMedias.length}
                description={Boolean(description)}
                disablePadding={Boolean(disablePadding)}
                descriptionHeight={height}
            >
                {filteredMedias.map((media, i) => (
                    <TextWrapper key={`${media.id}`}>
                        <If condition={i === 1 && description}>
                            <SectionDescriptionMobile>
                                <Text.h6Regular>
                                    <RichText>{description}</RichText>
                                </Text.h6Regular>
                            </SectionDescriptionMobile>
                        </If>
                        <If condition={media.mediaText && framed}>
                            <MediaDescription>
                                <Text.h6Regular>{media.mediaText}</Text.h6Regular>
                            </MediaDescription>
                        </If>
                        <If condition={i === 2 && description}>
                            <SectionDescriptionDesktop ref={descriptionRef}>
                                <Text.h6Regular>
                                    <RichText>{description}</RichText>
                                </Text.h6Regular>
                            </SectionDescriptionDesktop>
                        </If>
                        <ImageOrVideo media={media} isFramed={framed} />
                    </TextWrapper>
                ))}
            </Content>
            {Boolean(bottomSecondBackground) && (
                <>
                    <HalfBackground background={imagesAndVideosBackground} />
                    <HalfBackground background={bottomSecondBackground} isBottom />
                </>
            )}
        </ImagesAndVideosContainer>
    );
};

export default ImagesAndVideosSection;
