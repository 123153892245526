import styled from 'styled-components';

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const ListElement = styled.div`
    display: flex;
    gap: 16px;
    img {
        width: fit-content;
        height: fit-content;
    }
`;
