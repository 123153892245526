import styled, {css} from 'styled-components';

import {above} from '~config/breakpoints';

export const FontsTestSectionContainer = styled.section<{
    theme: 'dark' | 'light' | 'orange' | 'strapi';
}>`
    width: 100%;
    min-height: 100vh;
    display: grid;
    gap: 64px;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    padding: 50px var(--h-padding);

    ${above.laptop} {
        grid-template-columns: repeat(2, 1fr);
    }

    color: var(--dark-grey);

    ${({theme}) => {
        switch (theme) {
            case 'dark': {
                return css`
                    background: var(--dark-grey);
                    color: var(--white) !important;
                `;
            }
            case 'strapi': {
                return css`
                    background: #27273e;
                    color: var(--white) !important;
                `;
            }
            case 'light': {
                return css`
                    background: var(--white);
                `;
            }
            case 'orange': {
                return css`
                    background: var(--orange);
                    color: var(--white) !important;
                `;
            }
        }
    }};
`;

export const LeftContainer = styled.div`
    display: grid;
    gap: 8px;
`;

export const Separator = styled.div`
    margin-bottom: 40px;
`;

export const Group = styled.div`
    margin-bottom: 40px;
`;
